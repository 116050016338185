import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from "../shared/auth/react-auth0-spa";
import UploadContainer from '../pages/uploader/UploadContainer';
import { 
    UPLOAD_RETRO_FILES,
    CHANGE_DISPLAY_NAME, 
    CHANGE_TIMEZONE, 
    CHANGE_THRESHOLDS, 
    CHANGE_UNITS 
} from '../shared/configs/PERMISSIONS_TOOLS';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { 
    Menu as MenuIcon, 
    ChevronRight,
    MoreVert
} from '@mui/icons-material';

TopBarContainer.propTypes = {
    toggleSideBar: PropTypes.func.isRequired
}


function TopBarContainer(props){

    const { logout } = useAuth0();
    
    const { toggleSideBar, setSettingsPage } = props;

    // state
    const [anchorEl, setAnchorEl] = useState(null); // settings icon
    const [settingsOpen, setSettings] = useState(false); // settings icon
    const [isModalOpen, setModalOpenState] = useState(false); // Modal open/close

    const  organizationID  = useSelector(state => state.app.getIn(['organization', 'name'], 'noOrgID'));
    const permissions = useSelector(state => state.app.getIn(['user', 'permissions'], List([])));
    
    // func
    const openSettings = () => setSettings(true);
    const closeSettings = () => setSettings(false);

    const openModal = () => {
        hideSettings();
        setModalOpenState(true);
    }
    const closeModal = () => setModalOpenState(false);


    const displaySettings = (event) => {
        if (anchorEl === null && !settingsOpen) {
            openSettings();
            setAnchorEl(event.currentTarget);
        }
    }
    const hideSettings = () => {
        setAnchorEl(null);
        closeSettings(false);
    }

    const _hideSettings = () => {
        setSettingsPage(true);
        setAnchorEl(null);
        closeSettings(false);
    }

    const _toggleSideBar = () => {
        toggleSideBar(true);
    }

    const _logout = () => logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL });


    const hasSettingsPermissions = permissions.includes(CHANGE_DISPLAY_NAME) 
        || permissions.includes(CHANGE_TIMEZONE)
        || permissions.includes(CHANGE_UNITS)
        || permissions.includes(CHANGE_THRESHOLDS);
    return (
        <Box>
            <AppBar position="static" sx={{bgcolor: 'primary.secondary'}}>
            <Toolbar variant="dense">
                <Grid item xs={1}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onMouseEnter={_toggleSideBar}
                        size="large">
                        <MenuIcon />
                        <ChevronRight style={{ marginLeft: -8 }} />
                    </IconButton>
                </Grid>
                <Grid 
                    item 
                    xs={11} 
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <IconButton style={{ color: "white" }} onClick={displaySettings} size="large">
                        <MoreVert />
                    </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={settingsOpen}
                    onClose={hideSettings}
                >
                    { hasSettingsPermissions && (
                    <Link 
                        to={`/settings/${organizationID}`}
                        style={{ color: "black", textDecoration: "none"}}
                    >    
                        <MenuItem onClick={_hideSettings}>
                                Settings
                        </MenuItem>
                    </Link>
                    )}
                    {permissions.includes(UPLOAD_RETRO_FILES) && (
                    <MenuItem onClick={openModal}>
                        <div>Retro Uploader</div>
                    </MenuItem>
                    )}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href='https://skipline.com/user-guide/'
                        style={{ color: "black", textDecoration: "none"}}
                    >    
                        <MenuItem onClick={hideSettings}>
                                User Guide
                        </MenuItem>
                    </a>
                        <MenuItem onClick={_logout}>Log Out</MenuItem>
                    </Menu>
                </Grid>
            </Toolbar>
            {isModalOpen && (
                <UploadContainer 
                    isModalOpen={isModalOpen} 
                    closeModal={closeModal} 
                />
            )}
        </AppBar>
    </Box>
    );
};


export default TopBarContainer;
