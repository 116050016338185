import Immutable from 'immutable';

const dateRangeResetLayerObj = Immutable.fromJS({
    nameOfSelectedSummary: [{label: 'All', value: 'all'}],
    selectedSource: [{label: 'All', value: 'all'}],
    selectedSegment: '',
    segmentFeatureCollection: {}
});

export default dateRangeResetLayerObj;
