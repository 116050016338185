import Immutable, { merge, Map, List } from 'immutable';
import { appActionTypes } from '../../../shared/stores/app_actionTypes';



const DUPLICATE_SCHEDULED_EVENT = 'DUPLICATE_SCHEDULED_EVENT';
const REMOVE_EVENT_FROM_LIST = 'REMOVE_EVENT_FROM_LIST';
const UPDATE_SCHEDULED_EVENTS_LIST = 'UPDATE_SCHEDULED_EVENTS_LIST';
const SET_AUTO_REPORT_EVENT_LIST_DATA = 'SET_AUTO_REPORT_EVENT_LIST_DATA';
const TOGGLE_AUTO_REPORT_MODAL = 'toggleReportModal';
const UPDATE_NAME = 'updateName';
const UPDATE_COLOR = 'updateColor';
const UPDATE_DATE_TIME = 'updateDateTime';
const UPDATE_TEMPLATE_ID = 'updateTemplateID';
const UPDATE_JOBS_AND_EQUIPMENT = 'updateJobsAndEquipment';
const UPDATE_DELIVERY_OPTIONS = 'UPDATE_DELIVERY_OPTIONS';


const SET_EVENT_DATA_FOR_EDITING = 'SET_EVENT_DATA_FOR_EDITING';

export const duplicateEvent = (duplicateEvent) => ({
    type: DUPLICATE_SCHEDULED_EVENT,
    duplicateEvent,
});

export const removeEventFromList = (apiResponse) => ({
    type: REMOVE_EVENT_FROM_LIST,
    apiResponse,
});

export const updateScheduledEventsList = (newEvent) => ({
    type: UPDATE_SCHEDULED_EVENTS_LIST,
    newEvent,
});

export const setAllScheduledEvents = (scheduledEvents) => ({
    type: SET_AUTO_REPORT_EVENT_LIST_DATA,
    scheduledEvents,
});

export const setScheduledAutoReportData = (scheduledEvent, isEditing) => ({
    type: SET_EVENT_DATA_FOR_EDITING,
    scheduledEvent,
    isEditing,
});

export const updateDeliveryOptions = (deliveryOptions) => ({
    type: UPDATE_DELIVERY_OPTIONS,
    deliveryOptions
});

export const updateJobsAndEquipment = (jobs, equipment) => ({
    type: UPDATE_JOBS_AND_EQUIPMENT,
    jobs,
    equipment,
})

export const updateTemplateID = (templateID) => ({
    type: UPDATE_TEMPLATE_ID,
    templateID
});

export const updateDateTime = (dateTime) => ({
    type: UPDATE_DATE_TIME,
    dateTime
});

export const updateAutoReportColor = (color) => ({
    type: UPDATE_COLOR,
    color,
});

export const updateAutoReportName = (name) => ({
    type: UPDATE_NAME,
    name,
});

export const toggleAutoReportModal = (isReportModalOpen) => ({
    type: TOGGLE_AUTO_REPORT_MODAL,
    isReportModalOpen
});


const mergeDeep = (orginalObj, newObj, isUpdate=true) => {
    let mergedObj = orginalObj.map((value, key) => {
        if (Map.isMap(value)) {
            const _newObj = newObj.get(key, Map({}));
            return mergeDeep(value, _newObj);
        };

        const newValue = newObj.get(key, '');
        if (!newValue) {
            return value;
        }

        return newValue;
    });

    const newID = newObj.get('id', '');
    if (newID && isUpdate) {
        mergedObj = mergedObj.set('id', newID);
    }

    return mergedObj
};


const date = new Date()
const todaysDate = date.toISOString().split('T')[0]
const initialAutoReportState = Immutable.fromJS({
    name: '',
    equipment: [],
    jobs: [],
    color: '#037C3B',
    templateID: '',
    orgID: '',
    userID: '',
    includeMap: false,

    deliveryOptions: {
        deliveryMethods: ["email"],
        whenToDeliver: "immediately",
        emails: '',
        phoneNumber: [],
        attachmentTypes: ["pdf"],
        // includeAs
        includeAs: ["link"],
        sftpUsername: '',
        sftpIPAddress: '',
        sftpPassword: '',
        custom_whenToDeliver: {
            amount: 10,
            unit: 'minutes',
        }
    },
    dateTime: {
        frequency: "daily",
        runTime: '06:00',
        endOn: {
            value: "never",
            endOnDate: todaysDate,
            amountOfOccurrences: 5
        },
        weekly_daysOfWeek: ['M'],
        daily_daysOfWeek: "workWeek",
        startDate: todaysDate
    }
});

const initalState = Immutable.fromJS({
    defaultEmail: '',
    isReportModalOpen: false,
    newAutoReport: initialAutoReportState,
    isEditing: true,
});

const isDateBefore = (_date) => {
    let newDate = _date;
    const ogStartDate = new Date(newDate);
    const today = new Date(todaysDate);
    if (ogStartDate < today) {
        newDate= todaysDate;
    }
    return newDate;

};

// set user email when app loads

const reportingReducer = (state = initalState, action) => {
    switch (action.type) {


        case DUPLICATE_SCHEDULED_EVENT: 
            let duplicateEvent = action.duplicateEvent;
            let eventName = duplicateEvent.get('name');
            eventName = `DUPLICATE-${eventName}`;
            duplicateEvent = duplicateEvent.delete('id');
            duplicateEvent = duplicateEvent.set('name', eventName);

            const _runTime = duplicateEvent
                .getIn(['dateTime', 'runTime'], '')
                .split(' ')[0]
            duplicateEvent = duplicateEvent.setIn(['dateTime', 'runTime'], _runTime);

            let _startDate = duplicateEvent.getIn(['dateTime', 'startDate'], '');
            if (_startDate) {
                _startDate = isDateBefore(_startDate);
            }
            let _endDate = duplicateEvent.getIn(['dateTime', 'endOn', 'endOnDate'], '');
            if (_endDate) {
                _endDate = isDateBefore(_endDate);
            }

            duplicateEvent = duplicateEvent
                .setIn(['dateTime', 'startDate'], _startDate)
                .setIn(['dateTime', 'endOn', 'endOnDate'], _endDate);

            // turn the email array in a string
            const _emails = duplicateEvent
                .getIn(['deliveryOptions', 'emails'])
                .join(', ');
            duplicateEvent = duplicateEvent.setIn(['deliveryOptions', 'emails'], _emails);

            const _initEventData = state.get('newAutoReport');
            const _updatedAutoReport = mergeDeep(_initEventData, duplicateEvent, false); 
            return state
                .set('newAutoReport', _updatedAutoReport)
                .set('isReportModalOpen', true);


        case REMOVE_EVENT_FROM_LIST: 
            const _eventID = action.apiResponse.get('id', '');
            let _allScheduledEvents = state.get('scheduledEvents', List([]));
            const _indexOfEvent = _allScheduledEvents.findIndex(event => {
                return event.get('id', null) === _eventID;
            });

            _allScheduledEvents = _allScheduledEvents.delete(_indexOfEvent);
            return state.set('scheduledEvents', _allScheduledEvents);

        case UPDATE_SCHEDULED_EVENTS_LIST:
            const newEvent = action.newEvent;
            const eventID = newEvent.get('id', '');
            let allScheduledEvents = state.get('scheduledEvents', List([]));
            const indexOfEvent = allScheduledEvents.findIndex(event => {
                return event.get('id', null) === eventID;
            })

            if(indexOfEvent === -1){
                allScheduledEvents = allScheduledEvents.push(newEvent);
            } else {
                allScheduledEvents = allScheduledEvents.set(indexOfEvent, newEvent);
            }

            return state.set('scheduledEvents', allScheduledEvents);

        case SET_AUTO_REPORT_EVENT_LIST_DATA:
            return state.set('scheduledEvents', action.scheduledEvents);

        case SET_EVENT_DATA_FOR_EDITING:
            let { scheduledEvent, isEditing } = action;
            // removing the timezone from runTime
            const runTime = scheduledEvent
                .getIn(['dateTime', 'runTime'], '')
                .split(' ')[0]
            scheduledEvent = scheduledEvent.setIn(['dateTime', 'runTime'], runTime);

            // turn the email array in a string
            const emails = scheduledEvent
                .getIn(['deliveryOptions', 'emails'])
                .join(', ');
            scheduledEvent = scheduledEvent.setIn(['deliveryOptions', 'emails'], emails);

            const initReportObject = state.get('newAutoReport')
            const updatedAutoReport = mergeDeep(initReportObject, scheduledEvent);



            return state
                .set('newAutoReport', updatedAutoReport)
                .set('isEditing', isEditing)
                .set('isReportModalOpen', true);

        case appActionTypes['user/setUserData']:
            const email = action.user.get('email');
            return state
                .setIn(['newAutoReport', 'deliveryOptions', 'emails'], email)
                .set('defaultEmail', email);

        case UPDATE_JOBS_AND_EQUIPMENT: 
            const { jobs, equipment } = action;
            return state
                .setIn(['newAutoReport', 'jobs'], Immutable.fromJS(jobs))
                .setIn(['newAutoReport', 'equipment'], Immutable.fromJS(equipment))

        case UPDATE_TEMPLATE_ID:
            return state.setIn(['newAutoReport', 'templateID'], action.templateID);

        case UPDATE_DELIVERY_OPTIONS:
            return state.setIn(['newAutoReport', 'deliveryOptions'], action.deliveryOptions);

        case UPDATE_DATE_TIME:
            return state.setIn(['newAutoReport', 'dateTime'], action.dateTime);

        case UPDATE_COLOR:
            return state.setIn(['newAutoReport', 'color'], action.color);

        case UPDATE_NAME:
            return state.setIn(['newAutoReport', 'name'], action.name);

        case TOGGLE_AUTO_REPORT_MODAL:
            const isReportModalOpen = action.isReportModalOpen;

            if (isReportModalOpen){
                return state.set('isReportModalOpen', isReportModalOpen);

            } else {
                const defaultEmail = state.get('defaultEmail');
                const _initialAutoReportState = initialAutoReportState
                    .setIn(['deliveryOptions', 'emails'], defaultEmail)
                return state
                    .set('newAutoReport', _initialAutoReportState)
                    .set('isReportModalOpen', false)
                    .set('isEditing', true);
            }

            // case 'updateDeliveryMethodType':
            //     return state.setIn(['delivery_method', 'type'], action.deliveryType);

            // case UPDATED_DELIVERY_FORMAT:
            //     return state.setIn(['delivery_method', 'includeAs'], action.includeAs);

            // case UPDATE_WHEN_TO_DELIVER:
            //     return state.setIn(['delivery_method', 'when_to_deliver'], action.when);

        default:
            return state;
    };
};


export default reportingReducer;
