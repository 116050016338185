import React, { useState } from 'react';

import SideBarContainer from './SideBarContainer';
import TopBarContainer from './TopBarContainer';
import { Grid } from '@mui/material';


function NavBarContainer(props){

    const [sideBarOpen, setSideBarState] = useState(false);
    const [settingsPage, setSettingsPage] = useState(false);

    const toggleSideBar = (bool) => setSideBarState(bool);

    return (
        <Grid item xs={12}>
            <TopBarContainer 
                toggleSideBar={toggleSideBar} 
                setSettingsPage={setSettingsPage}
            />
            <SideBarContainer 
                toggleSideBar={toggleSideBar} 
                sideBarOpen={sideBarOpen} 
                settingsPage={settingsPage} 
                setSettingsPage={setSettingsPage}
            />
        </Grid>
    )
}


export default NavBarContainer;
