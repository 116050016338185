// equipment 
export const RENAME_TRUCK = "rename_truck";
export const CHANGE_LOGGER_TYPE = "change_logger_type";
export const CHANGE_TRUCK_TYPE = "change_truck_type";
export const CHANGE_LOCATION_SHARING = "change_location_sharing";

// organization settings
export const CHANGE_TIMEZONE = "change_timezone";
export const CHANGE_THRESHOLDS = "change_thresholds";
export const CHANGE_UNITS = "change_units";
export const CHANGE_ORG = "change_org";
export const CHANGE_DISPLAY_NAME = "change_display_name";

// report permissions
export const UPLOAD_REPORT_TEMPLATE = "upload_report_template";
export const DELETE_REPORT= "delete_report";

// user permissions
export const ALLOW_DEV_ORG = "allow_dev_org";
export const INVITE_USERS = "invite_users";
export const EDIT_USERS = "edit_users"

// internal dashboard permissions
export const INTERNAL_DASHBOARD = "internal_dashboard"

// retro 
export const UPLOAD_RETRO_FILES = "upload_retroreflectivity";
