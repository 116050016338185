import Immutable from 'immutable';
const submittedLayerObj = Immutable.fromJS({
    type: 'submitted',
    label: 'Submitted',
    idColor: 'green',
    advancedFilters: {},
});


export default submittedLayerObj;
