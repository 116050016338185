import Immutable from 'immutable';


const baseLayerObj = Immutable.fromJS({
    dateRangeOption: '30',
    // advancedFilters: {
    //     segmentation: 'pattern',
    // },
    nameOfSelectedSummary: [
        {
            value: 'all',
            label: 'All',
        }
    ],
    selectedSource: [
        {
            value: 'all',
            label: 'All',
        }
    ],
    summaryNames: [
        { 
            value: 'all',
            label: 'All',
        }
    ],
    sourceNames: [
        { 
            value: 'all',
            label: 'All',
        }
    ],

    selectedSegment: '',
    visibility: true,
    featureCollection: null,
    segmentFeatureCollection : {},
});


export default baseLayerObj;
