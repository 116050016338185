
const sortObjectsByProperty = (objectsToSort, propertyToSortBy) => {
    const sortedObjects = objectsToSort.sort((objectA, objectB) => {
        const propertyA = objectA.get(propertyToSortBy, false);
        const propertyB = objectB.get(propertyToSortBy, false);

        let returnValue = 0;
        if (propertyA < propertyB) returnValue = 1
        else if (propertyA > propertyB) returnValue = -1

        return returnValue;
    });

    return sortedObjects;
};


export {
    sortObjectsByProperty,
}
