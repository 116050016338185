import Immutable from 'immutable';
import { DateTime } from 'luxon';

// m is for marketing, these are the date ranges for used specific for the
// marketing org.
// export const m_today = new Date(2022, 2, 8);
// export const _m_priorDate = new Date(2021, 7, 4);
export const m_today = new Date(2020, 11, 1);
export const _m_priorDate = new Date(2020, 4, 1);
export const m_todaysDate = DateTime.fromJSDate(m_today).toString().split('T')[0]
export const m_priorDate = DateTime.fromJSDate(_m_priorDate).toString().split('T')[0];

const marketingDateObj = Immutable.fromJS({
    dateRangeOption: 'custom',
    dateRange: [m_priorDate, m_todaysDate]
});


export default marketingDateObj;
