import Immutable, { List, Map } from 'immutable';
import { appActionTypes } from '../../../shared/stores/app_actionTypes';
import { OrgDateTime } from '../../../Root';
import {skyviewActionTypes} from './skyview_action_types';

// Layer Objects
import { 
    applicationLayerObj, 
    baseLayerObj,
    layerResetObject,
    retroLayerObj,
    marketingDateObj,
    submittedLayerObj
} from './layer_objects';
// Utils
import { 
    sortObjectsByProperty,
    filterChangesResetState,
} from './utils'

// Action Types
// export const skyviewActionTypes = {
//     'skyview/setSourceIDAndName': 'skyview/setSourceIDAndName',
//     'skyview/setSelectedSummaryName': 'skyview/setSelectedSummaryName',
//     'skyview/loadingSummaryNames': 'skyview/loadingSummaryNames',

//     'skyview/setSegmentationType': 'skyview/setSegmentationType',
//     'skyview/setSegmentationTypes': 'skyview/setSegmentationTypes',
//     'skyview/setSegmentationID': 'skyview/setSegmentationID',
//     'skyview/setMapView': 'skyview/setMapView',
//     'skyview/updateExtraLayers': 'skyview/updateExtraLayers',
//     'skyview/setSummaryNames': 'skyview/setSummaryNames',
//     'skyview/setSourceNames': 'skyview/setSourceNames',
//     'skyview/setSelectedLayer': 'skyview/setSelectedLayer',
//     'skyview/loadingSourceNames':'skyview/loadingSourceNames', 
//     'skyview/toggleLayersPanel':'skyview/toggleLayersPanel', 
//     'skyview/toggleLOP':'skyview/toggleLOP', 
//     'skyview/toggleAdvancedFilters':'skyview/toggleAdvancedFilters', 
//     'skyview/toggleLayerVisibility': 'skyview/toggleLayerVisibility',
//     'skyview/resetLayer': 'skyview/resetLayer',
//     'skyview/addLayer': 'skyview/addLayer',
//     'skyview/removeLayer': 'skyview/removeLayer',
//     'skyview/setMapDataLoading': 'skyview/setMapDataLoading',
//     'skyview/setFirstLoad': 'skyview/setFirstLoad',
//     'skyview/setLayerFeatureCollection': 'skyview/setLayerFeatureCollection',
//     'skyview/handleMapLayerClick': 'skyview/handleMapLayerClick',
//     'skyview/setMapLayerIdAndListeners': 'skyview/setMapLayerIdAndListeners',
//     'updateDateRange': 'updateDateRange',
//     'clearLayerUpdates': 'clearLayerUpdates',
//     'toggleLayerVisibility': 'toggleLayerVisibility',
//     'dropSelectedSegment': 'dropSelectedSegment',
//     'toggleExportModal': 'toggleExportModal',
//     'setReportId': 'setReportId',

// };


// Actions
// LOP

export const toggleExportModal = (bool) => ({
    type: skyviewActionTypes['toggleExportModal'],
    bool
});
export const setReportId = (id) => ({
    type: skyviewActionTypes['setReportId'],
    id
});
export const dropSelectedSegment = () => ({
    type: skyviewActionTypes['dropSelectedSegment'],
})

export const setMapLayerIdAndListeners = (mapLayerId, _mapLayerIndex, listeners) => ({
    type: skyviewActionTypes['skyview/setMapLayerIdAndListeners'],
    mapLayerId,
    _mapLayerIndex,
    listeners
})


export const handleMapLayerClick = (layerType, mapLayerIndex, data, featureIndex) => ({
    type: skyviewActionTypes['skyview/handleMapLayerClick'],
    layerType,
    mapLayerIndex,
    data,
    featureIndex
});

export const setLayerFeatureCollection = (featureCollection, layerIndex) => ({
    type: skyviewActionTypes['skyview/setLayerFeatureCollection'],
    featureCollection,
    layerIndex
});

export const setFirstLoad = (bool) => ({
    type: skyviewActionTypes['skyview/setFirstLoad'],
    bool
});

export const setMapDataLoading = (bool) => ({
    type: skyviewActionTypes['skyview/setMapDataLoading'],
    bool
});

export const removeLayer = (layerID) => ({
    type: skyviewActionTypes['skyview/removeLayer'],
    layerID
})

export const addLayer = (layerType) => ({
    type: skyviewActionTypes['skyview/addLayer'],
    layerType
})

export const resetLayer = (layerIndex) => ({
    type: skyviewActionTypes['skyview/resetLayer'],
    layerIndex
})

export const toggleLayerVisibility = (layerIndex) => ({
    type: skyviewActionTypes['skyview/toggleLayerVisibility'],
    layerIndex
})

export const toggleLOP = (bool) => ({
    type: skyviewActionTypes['skyview/toggleLOP'],
    bool
})

export const toggleAdvancedFilters = () => ({
    type: skyviewActionTypes['skyview/toggleAdvancedFilters'],
})

export const toggleLayersPanel = (bool) => ({
    type: skyviewActionTypes['skyview/toggleLayersPanel'],
    bool
})

export const setLoadingSummaryNames = (bool) => ({
    type: skyviewActionTypes['skyview/loadingSummaryNames'],
    bool
})

export const setLoadingSourceNames = (bool) => ({
    type: skyviewActionTypes['skyview/loadingSourceNames'],
    bool
})

export const setSummaryNames = (summaryNames, layerIndexForSummaryNames) => ({
    type: skyviewActionTypes['skyview/setSummaryNames'],
    summaryNames,
    layerIndexForSummaryNames
})

export const setSourceNames = (sourceNames, layerIndex) => ({
    type: skyviewActionTypes['skyview/setSourceNames'],
    sourceNames,
    layerIndex
})

export const setSelectedLayer = (layerIndex) => ({
    type: skyviewActionTypes['skyview/setSelectedLayer'],
    layerIndex
});

export const updateExtraLayers = (layer) => ({
    type: skyviewActionTypes['skyview/updateExtraLayers'],
    layer
});


export const setMapView = (view) => ({
    type: skyviewActionTypes['skyview/setMapView'],
    view
});


export const setSegmentationID = (id) => ({
    type: skyviewActionTypes['skyview/setSegmentationID'],
    id
});

export const setSourceIDAndName = (sourceObj) => ({
    type: skyviewActionTypes['skyview/setSourceIDAndName'],
    sourceObj 
});

export const setSelectedSummaryName = (name) => ({
    type: skyviewActionTypes['skyview/setSelectedSummaryName'],
    name
});

export const setSegmentationType = (segType) => ({    
    type: skyviewActionTypes['skyview/setSegmentationType'],
    segType
});
// Sets options for the segmentation type dropdown
export const setSegmentationTypes = (segTypes) => ({    
    type: skyviewActionTypes['skyview/setSegmentationTypes'],
    segTypes
});

export const resetSkyviewStore = () => ({
    type: skyviewActionTypes['skyview/reset']
});

export const updateDateRange = ({range, dates=[], layerIndexDR}) => ({
    type: skyviewActionTypes['updateDateRange'],
    range,
    dates,
    layerIndexDR
});

export const clearLayerUpdates = () => ({
    type: skyviewActionTypes['clearLayerUpdates'],
});



let layerTypes = {
    application: baseLayerObj.merge(applicationLayerObj),
    retroreflectivity: baseLayerObj.merge(retroLayerObj),
    submitted: baseLayerObj.merge(submittedLayerObj)
}

let layerColors = List(['#08bf8b', '#99187f', '#0a61d1', '#00a4c4', '#6b25b1']);

const initialState = Immutable.fromJS({
    advancedFiltersOpen: false,
    layerPanelOpen: true,
    selectedLayer: 0, // index of selected layer
    loadingSummaryNames: false,
    loadingSourceNames: false,
    LOPopen: true,
    firstLoad: true, // indicates if this is the first time all layer data is being fetched
    mapDataLoading: false, 
    view: 'dark-v10',
    commentsLayer: false,
    mileMarkersLayer: false,
    layerUpdates: null,
    // either null or an object
    // {
    //      action: 'update',
    //      data: <index><array of layers><mapLayerId>,
    // }
    segmentationTypes: {},
    layers: [],
    hasViewChange: false,
    exportLayerModelOpen: false,
    reportId: '',

});

// Davis or date range
const davisOrgDateRange = Immutable.fromJS({
    dateRangeOption: 'custom',
    dateRange: ['2020-06-01', '2020-06-30']
});


const skyviewReducer = (state=initialState, action) => {

    const selectedLayer = state.get('selectedLayer', 0);
    const is_marketing = state.get('marketingOrg', false);

    switch(action.type){

            // LOP

        case skyviewActionTypes['setReportId']:
            const _reportId = action.id;
            const _updatedReportState = state
                .set('reportId', _reportId)
                .set('exportLayerModelOpen', true);
            return state.merge(_updatedReportState);

        case skyviewActionTypes['toggleExportModal']:
            const _exportState = action.bool;
            let _updatedExportState = state
                .set('exportLayerModelOpen', _exportState)
                .set('reportId', '');

            // if export state was true aka open and now we are closing it
            // we also want to clear out the template id
            if (!_exportState) {
                _updatedExportState = _updatedExportState.set('reportId', '');
            }

            return state.merge(_updatedExportState);

        case appActionTypes['org/setOrg']:
            const _organizationObj = action.org;
            const _orgName = _organizationObj.get('name', '');
            const timeZone = _organizationObj.get('timezone', '');
            OrgDateTime.setTimeZone(timeZone);
            const [date_to_30, date_from_30] = OrgDateTime.getDatesFromRange(30);

            // const _orgLayerTypes = List(['application', 'retro']);
            const _orgLayerTypes = List(['application']);

            const _initialLayers = _orgLayerTypes.map(_layerType => {

                const idColor = layerColors.get(0);
                layerColors = layerColors.delete(0);
                let _layerObj = layerTypes[_layerType];
                _layerObj = _layerObj.set('dateRange', List([date_to_30, date_from_30]));
                // if marketing org we want to set the custom date range
                if (_orgName === 'Marketing') _layerObj = _layerObj.merge(marketingDateObj);
                if (_orgName === 'Davis') _layerObj = _layerObj.merge(davisOrgDateRange);
                _layerObj = _layerObj.set('idColor', idColor);
                return _layerObj;
            });

            let _initLayersState = state.set('layers', _initialLayers);
            if (_orgName === 'Marketing') {
                _initLayersState = _initLayersState.set('marketingOrg', true);
            }

            return state.merge(_initLayersState);


        case skyviewActionTypes['dropSelectedSegment']:
            return state
                .setIn(['layers', selectedLayer, 'selectedSegment'], '')
                .setIn(['layers', selectedLayer, 'segmentFeatureCollection'], {});


        case skyviewActionTypes['clearLayerUpdates']:
            return state.set('layerUpdates', null);


        case skyviewActionTypes['skyview/setMapLayerIdAndListeners']:
            const { mapLayerId, _mapLayerIndex, listeners } = action;
            const _mapLayerIdState = state
                .setIn(['layers', _mapLayerIndex, 'mapLayerId'], mapLayerId)
                .setIn(['layers', _mapLayerIndex, 'listeners'], listeners);


            return state.merge(_mapLayerIdState);


        case skyviewActionTypes['skyview/handleMapLayerClick']:

            // layerType here actaully refers to the map data type, whether it
            // is an overview or segment
            let { layerType, mapLayerIndex, data, featureIndex } = action;


            const isDataLoading = state.get('mapDataLoading', false);
            if (!isDataLoading){
                let _mapClickState = state.set('mapDataLoading', true);

                if ( selectedLayer !== mapLayerIndex ) {
                    _mapClickState = _mapClickState.set('selectedLayer', mapLayerIndex);
                }

                let _mapLayerClickState = _mapClickState.getIn(
                    ['layers', mapLayerIndex], false
                );

                // there should be a situation where this is false but doesn't hurt
                // to check
                if (_mapLayerClickState) {
                    // break these out into utility functions
                    if ( layerType === 'overview' ){
                        _mapLayerClickState = _mapLayerClickState.setIn(
                            ['nameOfSelectedSummary', 0], data
                        );
                        _mapLayerClickState = _mapLayerClickState.set(
                            'selectedSegment', ''
                        );
                        _mapClickState = _mapClickState.set('layerUpdates', Map({
                            type: 'update',
                            data: mapLayerIndex
                        }))

                    } else if ( layerType === 'segment' || layerType === 'point' ) {
                        const _segmentIdMapClick = data.get('value', '');;
                        // feature collections are not immutable;
                        const _featureCollection = _mapLayerClickState.get(
                            'featureCollection', false
                        );
                        if (_featureCollection) {
                            const _selectedSegmentFeature = _featureCollection
                                .features[featureIndex];
                            _mapLayerClickState = _mapLayerClickState.set(
                                'segmentFeatureCollection', {
                                    // 'featureCollection', {
                                    type: 'FeatureCollection',
                                    features: [_selectedSegmentFeature]
                                }
                            );

                        }
                        _mapClickState = _mapClickState
                            .set('layerUpdates', Map({
                                type: 'segmentSelect',
                                data: mapLayerIndex
                            }))
                            .set('mapDataLoading', false);

                        _mapLayerClickState = _mapLayerClickState.set(
                            'selectedSegment', _segmentIdMapClick
                        );
                    }
                    _mapClickState = _mapClickState.setIn(
                        ['layers', mapLayerIndex], _mapLayerClickState
                    );

                }
                return state.merge(_mapClickState);
            } else return state;


        case skyviewActionTypes['skyview/setLayerFeatureCollection']:

            // featureCollection is not immutable and we want it that way
            // to large to for us to be turning it back and forth between being
            // immutable
            //

            let { featureCollection, layerIndex } = action;


            // const isOverview = state.getIn(['layers', layerIndex, 'nameOfSelectedSummary', 0, 'value'], 'all');

            // const _lineColor =  state.getIn(['layers', layerIndex, 'idColor'], 'blue');
            // const _layerIndexType =  state.getIn(['layers', layerIndex, 'type'], '');
            // const colorKey = _layerIndexType === 'application' ? 'linecolor' : 'color'; 
            // if (isOverview === 'all') {
            //     if (_lineColor 
            //         && featureCollection 
            //         && featureCollection.features 
            //         && featureCollection.features.length > 0
            //     ) {
            //         featureCollection.features.forEach((feature, index) => {
            //             featureCollection.features[index].properties[colorKey] = _lineColor;
            //         });
            //         // layerColors = layerColors.delete(0);
            //     }
            // }

                    // layerColors = layerColors.delete(0);

            let _stateFC = state
                .setIn(
                    ['layers', layerIndex, 'featureCollection'], featureCollection
                )
                .set('layerUpdates', Map({ type: 'updateFeatureCollection' }))
                .set('mapDataLoading', false);

            // if (isOverview === 'all') {
            //     _stateFC = _stateFC.setIn(['layers', layerIndex, 'idColor'], _lineColor);
            // }

            return state.merge(_stateFC);


        case skyviewActionTypes['skyview/setFirstLoad']:
            const bool = action.bool
            let _firstLoadState =  state.set('firstLoad', bool);
            return state.merge(_firstLoadState);


        case skyviewActionTypes['skyview/setMapDataLoading']:
            const  _mapDataLoading = action.bool;
            return state.set('mapDataLoading', _mapDataLoading);


        case skyviewActionTypes['skyview/removeLayer']:
            const _removeLayerID = action.layerID;

            let _removedLayerState = state.get('layers', List([]));
            let _updateLayersState = state;

            if (state.get('layers').size > 1 ){
                const layerIdsAndListeners = _removedLayerState.map(layer => {
                    return Map({
                        id: layer.get('mapLayerId'),
                        listeners: layer.get('listeners', List([]))
                    })
                });
                const _colorForArray = _removedLayerState.getIn([_removeLayerID, 'idColor'], '');
                if (_colorForArray) layerColors = layerColors.push(_colorForArray);
                _removedLayerState = _removedLayerState.delete(_removeLayerID);
                _updateLayersState = _updateLayersState.set('layers', _removedLayerState);
                _updateLayersState = _updateLayersState.set(
                    'mapDataLoading', true
                );
                _updateLayersState = _updateLayersState.set('layerUpdates', Map({
                    type: 'delete',
                    data: layerIdsAndListeners,
                }));

                if (_removeLayerID === selectedLayer) {
                    _updateLayersState = _updateLayersState.set('selectedLayer', 0);
                } else if (_removeLayerID < selectedLayer){
                    _updateLayersState = _updateLayersState.set(
                        'selectedLayer', selectedLayer - 1
                    );
                };
            }

            return state.merge(_updateLayersState);


        case skyviewActionTypes['skyview/addLayer']:
            const _newLayerType = action.layerType;
            //
            // console.log('action', action);
            // console.log('state', JSON.stringify(state.toJS()));
            //
            let _newLayerObj = layerTypes[_newLayerType];

            const [_date_to_30, _date_from_30] = OrgDateTime.getDatesFromRange(30);
            _newLayerObj = _newLayerObj.set('dateRange', List([_date_to_30, _date_from_30]));
            // if marketing org we want to set the custom date range
            if (is_marketing) _newLayerObj = _newLayerObj.merge(marketingDateObj);

            const idColor = layerColors.get(0);
            layerColors = layerColors.delete(0);
            _newLayerObj = _newLayerObj.set('idColor', idColor);



            let _newLayersState = state.get('layers', List([]));
            let _updatedSelectedLayer = selectedLayer;
            if (_newLayersState.size < 5) {
                _newLayersState = _newLayersState.push(_newLayerObj);
                _updatedSelectedLayer = _newLayersState.size - 1;
                _newLayersState = _newLayersState.set(
                    'newLayerToAdd', _newLayersState.size - 1
                );
            }
            let _addedLayerState = state.set('layers', _newLayersState);
            _addedLayerState = _addedLayerState.set(
                'selectedLayer', _updatedSelectedLayer
            )
                .set('layerUpdates', Map({
                    type: 'add',
                    data: _updatedSelectedLayer
                }))
                .set('mapDataLoading', true)

            return state.merge(_addedLayerState);

        case 'thresholdUpdate':
        case appActionTypes['updateUnitType']:
            const [__date_to_30, __date_from_30] = OrgDateTime.getDatesFromRange(30);
            const allLayers = state.get('layers', List([]));
            const layersReset = allLayers.map(_layer => {
                let newLayer = _layer.merge(layerResetObject);
                newLayer = newLayer.set("dateRange", List([__date_to_30, __date_from_30]));
                if (is_marketing) newLayer.merge(marketingDateObj);
                return newLayer
            });

            const _resetAllLayerState = state
                .set('mapDataLoading', true)
                .set('firstLoad', true)
                .set('layers', layersReset)

            return state.merge(_resetAllLayerState);


        case skyviewActionTypes['skyview/resetLayer']:

            const [___date_to_30, ___date_from_30] = OrgDateTime.getDatesFromRange(30);
            const _resetIndex = action.layerIndex;
            const _resetPath = ['layers', _resetIndex];

            let _resetLayer = state.getIn(_resetPath);
            _resetLayer = _resetLayer.merge(layerResetObject);
            _resetLayer = _resetLayer.set('dateRange', List([___date_to_30, ___date_from_30]));

            // if marketing org we want to set the custom date range
            if (is_marketing) _resetLayer = _resetLayer.merge(marketingDateObj);


            const _resetLayerState = state
                .set('mapDataLoading', true)
                .set('selectedLayer', _resetIndex)
                .set('layerUpdates', Map({
                    type: 'reset',
                    data: _resetIndex
                }))
                .setIn(_resetPath, _resetLayer);

            return state.merge(_resetLayerState);


        case skyviewActionTypes['skyview/toggleLayerVisibility']:
            const _layerVisibilityIndex = action.layerIndex;
            const _visibilityPath = ['layers', _layerVisibilityIndex, 'visibility'];
            const _currentLayerVisibility = state.getIn(_visibilityPath, false);
            const _layerMapId = state.getIn(['layers', _layerVisibilityIndex, 'mapLayerId'], false);

            let _visibilityState = state
                .setIn(_visibilityPath, !_currentLayerVisibility)
                .set('layerUpdates', Map({
                    type: 'visibility',
                    data: _layerMapId,
                    visibilityValue: !_currentLayerVisibility
                }))

            return state.merge(_visibilityState);


        case skyviewActionTypes['skyview/toggleAdvancedFilters']:
            const _advancedFiltersOpen = state.get('advancedFiltersOpen');;
            return state.set('advancedFiltersOpen', !_advancedFiltersOpen);


        case skyviewActionTypes['skyview/toggleLOP']:
            const _LOPopen = action.bool;
            return state.set('LOPopen', _LOPopen);


        case skyviewActionTypes['skyview/toggleLayersPanel']:
            const layersPanelBool = action.bool;
            return state.set('layerPanelOpen', layersPanelBool);


        case skyviewActionTypes['skyview/loadingSummaryNames']:
            const _loadingSummaryNames = action.bool;
            return state.set('loadingSummaryNames', _loadingSummaryNames);


        case skyviewActionTypes['skyview/loadingSourceNames']:
            const _loadingSourceNames = action.bool;
            return state.set('loadingSourceNames', _loadingSourceNames);


        case skyviewActionTypes['skyview/setSourceNames']:
            const _sourceNames = action.sourceNames;
            // sort the array by whether or not the source is available
            const _sourceNamesSorted = sortObjectsByProperty(_sourceNames, 'available');
            // create the object for the dropdown menu
            const __selectedLayerType = state.getIn(['layers', selectedLayer, 'type'], '');
            const __valueKey = __selectedLayerType === 'application' ? 'id' : 'serial_number';
            let sourceNamesSorted = _sourceNamesSorted.map(source => ({
                value: source.get(__valueKey, "No Name"),
                label: source.get("name", "No Name"),
                isDisabled: !source.get("available", true),
            }));

            // add the all option 
            sourceNamesSorted = sourceNamesSorted.unshift({
                value: 'all',
                label: 'All',
                isDisabled: false
            });

            const sourceNamesState = state.setIn(
                ['layers', selectedLayer, 'sourceNames'], sourceNamesSorted
            );

            return state.merge(sourceNamesState);


        case skyviewActionTypes['skyview/setSummaryNames']:
            const { summaryNames, layerIndexForSummaryNames} = action;
            const _summaryNamesSorted = sortObjectsByProperty(summaryNames, 'available');
            const selectedLayerType = state.getIn(['layers', selectedLayer, 'type'], '');
            // temp fix since the endpoints are returning different property
            // values for the same component
            const valueKey = selectedLayerType === 'application' ? 'name' : 'value';
            const labelKey = selectedLayerType === 'application' ? 'name' : 'label';

            let summaryNamesSorted = _summaryNamesSorted.map(summary => ({
                // reportNames.js uses 'id' and summaryNames uses 'name'
                value: summary.get(valueKey, "No Name"), 
                label: summary.get(labelKey, "No Name"),
                isDisabled: !summary.get("available", true),
            }));

            summaryNamesSorted = summaryNamesSorted.unshift({
                value: 'all',
                label: 'All',
                isDisabled: false
            });

            let summaryNamesState = state.setIn(
                ['layers', layerIndexForSummaryNames, 'summaryNames'], summaryNamesSorted
            );


            return state.merge(summaryNamesState);


        case skyviewActionTypes['skyview/setSelectedLayer']:
            const _newLayerIndex = action.layerIndex || 0;
            let _selectedLayerState = state.set('selectedLayer', _newLayerIndex);
            return state.merge(_selectedLayerState);

        case skyviewActionTypes['updateDateRange']:
            const { range, dates, layerIndexDR } = action;
            const  [startDate, endDate] = OrgDateTime.getDatesFromRange(range, dates);

            let _dateRangeState = state.set('dateRangeOption', range);
            // when date changes set changes and update state;
            _dateRangeState = _dateRangeState
                .setIn(
                    ['layers', selectedLayer, 'dateRangeOption'], range
                )
                .setIn(
                    ['layers', selectedLayer, 'dateRange', 0], startDate
                )
                .setIn(
                    ['layers', selectedLayer, 'dateRange', 1], endDate
                );

            const _dateRangeUpdateObj = Map({
                type: 'update',
                layer: layerIndexDR,

            })
            const _dateRangeLayers = _dateRangeState.get('layers', List([]));
            const _dateRangeUpdatedStateObj = filterChangesResetState(
                'date',
                _dateRangeUpdateObj,
                selectedLayer,
                _dateRangeLayers,
            );

            // reset all summary, source, segement related 
            _dateRangeState = _dateRangeState.merge(_dateRangeUpdatedStateObj);

            return state.merge(_dateRangeState);


        case skyviewActionTypes['skyview/setSourceIDAndName']:

            const sourceName = action.sourceObj;
            // set changes then merge in source reset obj
            let _sourceState = state.setIn(
                ['layers', selectedLayer, 'selectedSource', 0], sourceName
            )
            const _sourceUpdateObj = Map({ type: 'update', layer: selectedLayer })
            const _sourceLayers = _sourceState.get('layers', List([]));
            const _sourceUpdatedState = filterChangesResetState(
                'source',
                _sourceUpdateObj,
                selectedLayer,
                _sourceLayers
            );

            _sourceState = _sourceState.merge(_sourceUpdatedState);

            return state.merge(_sourceState);


        case skyviewActionTypes['skyview/setSelectedSummaryName']:
            const name = action.name || '';

            let summaryNameState = state.setIn(
                ['layers', selectedLayer, 'nameOfSelectedSummary', 0], name
            );
            const _summaryUpdateObj = Map({ type: 'update', layer: selectedLayer })
            const _summaryLayers = summaryNameState.get('layers', List([]));
            const _summaryUpdatedState = filterChangesResetState(
                'summary',
                _summaryUpdateObj,
                selectedLayer,
                _summaryLayers
            );

            summaryNameState = summaryNameState.merge(_summaryUpdatedState);

            return state.merge(summaryNameState);


        case skyviewActionTypes['skyview/setSegmentationType']:
            const type = action.segType;
            let _segTypeState = state
                .setIn(
                    ['layers', selectedLayer, 'advancedFilters', 'segmentation'], type
                )
                .setIn(
                    ['layers', selectedLayer, 'selectedSegment'], ''
                )
                .setIn(
                    ['layers', selectedLayer, 'segmentFeatureCollection'], Map({})
                )
                .set('layerUpdates', Map({
                    type: 'update',
                    data: selectedLayer,
                }))
                .set('mapDataLoading', true);

            return state.merge(_segTypeState);


        case skyviewActionTypes['skyview/setSegmentationTypes']:

            const segmentationTypes = action.segTypes;
            const applicationDefault = segmentationTypes.getIn(
                ['application', 0, 'value'], List([]));
            const submittedDefault = segmentationTypes.getIn(['submitted', 0, 'value'], List([]));
            const retroDefault = segmentationTypes.getIn(['retroreflectivity', 0, 'value'], List([]));

            if (applicationDefault !== null) {
                const applicationLayer = layerTypes.application.setIn(['advancedFilters', 'segmentation'], applicationDefault);
                layerTypes.application = applicationLayer
            };
            if (submittedDefault !== null) {
                const submittedLayer = layerTypes.submitted.setIn(['advancedFilters', 'segmentation'], submittedDefault);
                layerTypes.submitted = submittedLayer;
            };
            if (retroDefault !== null) {
                const retroLayer = layerTypes.retroreflectivity.setIn(['advancedFilters', 'segmentation'], retroDefault);
                layerTypes.retro = retroLayer;
            };


            const segmentationTypesState = state
                .set("segmentationTypes", segmentationTypes);

            return state.merge(segmentationTypesState);

        case skyviewActionTypes['skyview/setMapView']:

            const { view } = action;
            let _updatedMapViewState = state.set('view', view)

            return state.merge(_updatedMapViewState);


        case skyviewActionTypes['skyview/setSegmentationID']:
            const _segID = action.id.get('value', '');

            const _segIDState =  state
                .setIn(
                    ['layers', selectedLayer, 'selectedSegment'], _segID
                )
                .set('layerUpdates', Map({
                    type: 'update',
                    data: selectedLayer,
                }));

            return state.merge(_segIDState);



        case skyviewActionTypes['skyview/updateExtraLayers']:
            const extraLayerKey = action.layer;
            let currentLayerState = state.get(extraLayerKey, false);
            const _mapExtraLayers = state.set(extraLayerKey, !currentLayerState);

            return state.merge(_mapExtraLayers);


        default:
            return state;
    }
}


export default skyviewReducer;
