import Immutable from 'immutable';
const retroLayerObj = Immutable.fromJS({
    type: 'retroreflectivity',
    label: 'Retroreflectivity',
    idColor: 'purple',
    advancedFilters: {
        segmentation: 'point',
    },
});


export default retroLayerObj;
