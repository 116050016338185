import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { Paper, Typography, Button } from "@mui/material";
import cautionUnderConstruction from "../../images/cautionUnderConstruction.jpg";
import spr_image from "../../images/SPR_online_red-01.png";
import image from "../../images/image.png";
import { bugPageReportToSentry } from "../utils/sentry";

class MainContainerErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            eventId: null,
            url: null,
            user: null,
            gameTab: false,
            error: { name: "No name" },
        };
    }
    static getDerivedStateFromError(error) {
        console.error(error);
        return {
            hasError: true,
            error: error,
        };
    }

    toggleGameTab = () => {
        this.setState({ gameTab: !this.state.gameTab });
    };

    handleErrorReport = () => {
        Sentry.showReportDialog({ eventId: this.state.eventId });
    };

    hanldeEmailUpdateRequest = () => {
        const user = this.props.user.username;
        const email = this.props.user.email;
        const tool = this.props.tool;
        Sentry.captureMessage(
            `User : ${user} opts in for updates on ${tool}. Email : ${email}`
        );
    };

    componentDidCatch(error, info) {
        this.setState({ url: window.location.href });
        const { message, category } = this.props;
        // bugPageReportToSentry({error, message, category, info});
    }

    componentDidUpdate() {
        // Check to see if we have left the bugged page
        // This only works for when we leave the page
        // Need to figure out how to set this up so it will work on a smaller level
        if (this.state.url && window.location.href !== this.state.url) {
            this.setState({ hasError: false, url: null });
        }
    }

    render() {
        const { error, hasError, gameTab } = this.state;

        // Bug Page Error
        if (hasError && error && error.name !== "ChunkLoadError") {
            return (
                <div
                    style={{
                        width: "100%",
                        height: "70%",
                        marginLeft: "10em",
                        display: "flex",
                        marginTop: "10%",
                        justifyContent: "center",
                    }}
                >
                    <img
                        style={{ cursor: "pointer" }}
                        onClick={this.toggleGameTab}
                        height="300"
                        width="300"
                        src={
                            this.state.gameTab
                                ? image
                                : cautionUnderConstruction
                        }
                        alt="under construction sign"
                    />
                    <div style={{ maxWidth: "50%", marginLeft: "3em" }}>
                        {!gameTab && (
                            <React.Fragment>
                                <Typography variant="h5">
                                    This tool is currently in maintenance mode.
                                    Our team has identified a problem and are
                                    working to resolve it as soon as possible.
                                    Issues are normally taken care of within an
                                    hour. We apologize for any inconvenience.
                                </Typography>
                                <Typography variant="subtitle1">
                                    Our Development Team Email ::{" "}
                                    <b>support@spec-rite.io</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    If you would like to receive updates while
                                    the problem is resolved please click
                                    <div
                                        onClick={this.hanldeEmailUpdateRequest}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <b> EMAIL ME UPDATES </b>
                                    </div>
                                    and we will update you shortly.
                                </Typography>
                            </React.Fragment>
                        )}
                        <React.Fragment>
                            {gameTab && (
                                <div style={{ marginTop: "-9em" }}>
                                    <iframe
                                        title="Sophie's Crossing"
                                        src="https://withkoji.com/embed/acf40de2-21bd-496b-bc19-85fbbd6ffa2d?preventAutoplay=1"
                                        width="800"
                                        height="600"
                                        frameborder="0"
                                        allowfullscreen
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    </div>
                </div>
            );
            // Chunk Load Error from after an updated when old files are no
            // longer avaliable
        } else if (hasError && error && error.name === "ChunkLoadError") {
            return (
                <Paper
                    style={{
                        width: "100%",
                        height: "100%",
                        // marginLeft: '10em',
                        // marginRight: '10em',
                        display: "flex",
                        flexDirection: "column",
                        // marginTop: '10%',
                        // justifyContent: 'center',
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{ cursor: "pointer", marginTop: "10%" }}
                        // height="300"
                        width="50%"
                        src={spr_image}
                        alt="Spec-rite Logo"
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h4">
                            Spec-Rite Online has been updated!
                        </Typography>
                        <Typography variant="h5">
                            Please refresh to get the latest version.
                        </Typography>
                        <Button
                            style={{ width: "5em", margin: "1em" }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Refresh
                        </Button>
                    </div>
                </Paper>
            );
        }
        // If no Error is returns the wrapped component
        return this.props.children;
    }
}

MainContainerErrorBoundary.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
};

export default MainContainerErrorBoundary;
