import React from 'react';
// Components

const DashboardContainer = React.lazy(() => (
    import('../../pages/dashboard/DashboardContainer')
));
const EquipmentContainer = React.lazy(() => (
    import('../../pages/equipment/EquipmentContainer')
));
const JobsManagementContainer = React.lazy(() => (
    import('../../pages/jobs/JobsMgmtContainer')
));
const TemplatesContainer = React.lazy(() => (
    import('../../pages/templates/TemplatesContainer')
));
const SkyviewContainer = React.lazy(() => (
    import('../../pages/skyview/SkyviewContainer')
));
const ReportContainer = React.lazy(() => (
    import('../../pages/reports/ReportContainer')
));
// const SettingsContainer = React.lazy(() => (
//     import('../../settings/SettingsContainer')
// ));
const AdminContainer = React.lazy(() => (
    import('../../pages/admin/AdminContainer')
));
const ReportingContainer = React.lazy(() => (
    import('../../pages/reporting/ReportingContainer')
));
const SettingsContainer = React.lazy(() => (
    import('../../pages/settings/SettingsContainer')
));
const InternalDashboardContainer = React.lazy(() => (
    import('../../pages/internal_dashboard/InternalDashboardContainer')
));
const Page404 = React.lazy(() => (
    import('../components/Page404')
));


const routes = [
    {
        path: ['/dashboard', '/dashboard/:organizationID'], 
        component: DashboardContainer,
        errorMessage: 'BUG PAGE :: Dashboard Container Crashed',
        tool: 'Dashboard'
    },
    {
        path: ['/skyview', '/skyview/:organizationID','/skyview/:organizationID/new'],
        component: SkyviewContainer,
        errorMessage: 'BUG PAGE :: Skyview Container Crashed',
        tool: 'Skyview'
    },
    {
        path: ['/equipment', '/equipment/:organizationID'], 
        component: EquipmentContainer,
        errorMessage: 'BUG PAGE :: Equipment Container Crashed',
        tool: 'Equipment'
    },
    {
        path: ['/jobs', '/jobs/:organizationID'], 
        component: JobsManagementContainer,
        errorMessage: 'BUG PAGE :: Jobs Management Container Crashed',
        tool: 'Jobs Management'
    },
    {
        path: ['/reports', '/reports/:organizationID', '/reports/:organizationID/new'], 
        component: ReportContainer,
        errorMessage: 'BUG PAGE :: Report Container Crashed',
        tool: 'Report'
    },
    {
        path: ['/admin', '/admin/:organizationID'], 
        component: AdminContainer,
        errorMessage: 'BUG PAGE :: Admin Container Crashed',
        tool: 'Admin'
    },
    {
        path: ['/templates', '/templates/:organizationID'], 
        component: TemplatesContainer,
        errorMessage: 'BUG PAGE :: Templates Container Crashed',
        tool: 'Templates'
    },
    {
        path: ['/analytics', '/analytics/:organizationID'], 
        component: InternalDashboardContainer,
        errorMessage: 'BUG PAGE :: Internal Dashboard Container Crashed',
        tool: 'User Dashboard'
    },
    {
        path: ['/reporting', '/reporting/:organizationID'], 
        component: ReportingContainer,
        errorMessage: 'BUG PAGE :: Internal Dashboard Container Crashed',
        tool: 'Reporting'
    },
    {
        path: ['/settings', '/settings/:organizationID'], 
        component: SettingsContainer,
        errorMessage: 'BUG PAGE :: Settings Container Crashed',
        tool: 'Settings'
    },
    {
        path: '/*',
        errorMessage: 'BUG PAGE :: 404',
        tool: '404',
        component: Page404,
    }

];


export default routes;
