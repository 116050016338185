import React from 'react';
import { 
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';


function FileRow({
  fileObj,
  handleEquipmentSelect,
  index,
  removeFileFromList,
  equipment,
}){


  const { file, id, serialNumber } = fileObj;

  const name = file.name;


  const _handleEquipmentSelect = (event) => {
    const _serialNumber = event.target.value;
    handleEquipmentSelect(_serialNumber, index);
  };

  const _removeFile = () => {
    removeFileFromList(id);
  };

  return (
    <div 
      style={{
        display: 'flex',
        paddingLeft: '2em',
        // justifyContent: 'space-evenly',
        alignItems: 'baseline',
        minWidth: '100%',
        maxWidth: '100%',
      }}
    >
      <Typography style={{ maxWidth: '40%', minWidth: '40%', marginRight: '1em'}}>{name}</Typography>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Equipment</InputLabel>
        <Select
          labelId="equipmentSelect"
          id="equipmentSelect"
          value={serialNumber}
          style={{width: '10em', marginRight: '1em'}}
          onChange={_handleEquipmentSelect}
        >
          {equipment.map(equ => {
            const label = equ.get('name', 'No Name');
            const value = equ.get('serial_number', '');
            return (
              <MenuItem value={value} key={value}>{label}</MenuItem>
            )
          })}
        </Select>
        <FormHelperText style={{color: 'red'}}>*Required</FormHelperText>
      </FormControl>
      <Button 
        onClick={_removeFile}
        variant="contained">
        Delete
      </Button>
    </div>

  );
};


export default FileRow;
