import { createTheme, adaptV4Theme } from '@mui/material/styles';


const RED = '#CF2031';
const GREEN = '#037C3B';
const BLUE = '#1B3D9F';
const DARK_GREY = '#A2A2A2';
const LIGHT_GREY = '#DDDDDD';
const OFF_WHITE = '#FAFAFA';
const BLACK = '#212121';

// take theme and pass it to makeStyles inside a component
// theme is passed via a Material-UI React Context provider in Root.js
// export const theme = createTheme(adaptV4Theme({
export const theme = createTheme({
    // have to use given keys associated with theme object
    // i.e. palette, overrides, breakpoints, etc.
    palette: {
        primary: {
    
            secondary: RED,
            main: BLUE,
            red: RED,
            green: GREEN,
            blue: BLUE,
            darkGrey: DARK_GREY,
            offWhite: OFF_WHITE,
            black: BLACK,

        }, 

    },
    // Pass in any global overrides for specific Material-UI components
    overrides: {
        MuiButton: {
            label: {
                // Only capitalizes first letter of word; overrides capitalizing all letters
                textTransform: 'capitalize' 
            }
        },
    },
    typography: {
        color: BLACK,
        fontFamily: [
            'Montserrat',
            'Roboto',
            'Helevetica',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '24px',
            lineHeight: '29px',
        },
        h2: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '16px',
            lineHeight: '20px',
        },
        h4: {
            fontSize: '14px',
            lineHeight: '17px',
        },
        h5: {
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 600,
        },
        h6: {
            fontSize: '12px',
            lineHeight: '15px',
            fontWeight: 600,
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple!
            },
        },

        MuiButton: {

            variants: [
                {
                    props: { variant: 'red' },
                    style: {
                        backgroundColor: RED,
                        '&:hover': {
                            backgroundColor: RED,

                        }
                    },
                },
                {
                    props: { variant: 'white' },
                    style: {
                        backgroundColor: OFF_WHITE,
                        color: 'black',
                        '&:hover': {
                            backgroundColor: OFF_WHITE,

                        }
                    },
                },
            ],
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    // fontSize: '1rem',
                    textTransform: 'capitalize',
                    backgroundColor: BLUE,
                    borderColor: LIGHT_GREY,
                    color: OFF_WHITE,
                    '&:hover': {
                        border: 'none',
                        outline: `solid 3px ${LIGHT_GREY}`,
                        backgroundColor: BLUE,
                    },
                    '&:disabled': {
                        backgroundColor: LIGHT_GREY,
                        borderColor: DARK_GREY,
                        color: OFF_WHITE,
                        '&:hover': {
                            // border: 'none',
                            // outline: `solid 3px ${LIGHT_GREY}`,
                            // backgroundColor: BLUE,
                        }

                    },
                }
            }
        }
    }
});

const appStyle = theme => ({
    // Used for centering Spinner/Loading indicator
    centerInParentDiv: {
        position: "absolute",
        width: "95%",
        height: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        zIndex: 999,
    }
});

export default appStyle;
