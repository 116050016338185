import Immutable from 'immutable';


// this should work for both application and retro 
const layerResetObject = Immutable.fromJS({
    dateRangeOption: '30',
    nameOfSelectedSummary: [
        {
            value: 'all',
            label: 'All',
        }
    ],
    selectedSource: [
        {
            value: 'all',
            label: 'All',
        }
    ],
    selectedSegment: '',
    featureCollection: null,
    segmentFeatureCollection : {},
})

export default layerResetObject;
