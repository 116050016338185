import React from 'react';
import FileRow from './FileRow';
import { 
  Typography,
  Button,
} from '@mui/material';


function UploadFilesView({
  handleFileSelect,
  retroFiles,
  removeFileFromList,
  handleEquipmentSelect,
  equipment,
}){
  return (
    <>
      <div style={{display: 'flex', alignItems: 'baseline'}}>
        <Button
          color='primary'
          component='label'
          variant='contained'
        >
          Choose Files
          <input 
            multiple={true}
            onChange={handleFileSelect}
            type="file"
            accept=".csv, .txt"
            style={{display: 'none'}}
          />
        </Button>
        <Typography style={{marginLeft: '1em'}}>{retroFiles.length} Selected</Typography>
      </div>
      <div
        style={{
          margin: '4 auto',
          // marginBottom: '.5em',
          width: '100%',
          overflow: 'auto',
          maxHeight: '70%',
          minHeight: '70%',
        }}
      >
        {retroFiles.map((file, index) => {
          return (
            <FileRow 
              equipment={equipment}
              key={index}
              handleEquipmentSelect={handleEquipmentSelect}
              removeFileFromList={removeFileFromList}
              fileObj={file} 
              index={index}
            />
          )
        })}
      </div>
    </>
  );
};


export default UploadFilesView;
