import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { merge, List, Map } from 'immutable';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { removeSnackbar } from '../stores/app_s';

// Placeholder to keep track of currently displayed snackbars
let displayed = [];


function Snackbar(props) {

    const dispatch = useDispatch();
    // hooks from 'notistack' that show and remove snackbars from view
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Redux var; List of snackbars
    const notifications = useSelector(state => state.app.get("notifications", List([])));

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };


    useEffect(() => {
        try {
            notifications.forEach((snack) => {
                // Each snackbar has a unique key
                const uniqueID = snack.getIn(["options", "key"], Math.random());
                // Variable determines if dismissible
                const persist = snack.getIn(["options", "persist"], false);

                const _closeSnackbar = () => {
                    closeSnackbar(uniqueID);
                };
                // do nothing if snackbar is already displayed
                if (displayed.includes(uniqueID)) return;

                // Button to put with dismissible snackbars
                let button;
                if (persist) {
                    button = (
                        <Button 
                            onClick={_closeSnackbar}
                            variant="red"
                        >
                            Dismiss
                        </Button>
                    )
                } else {
                    button = "";
                }

                // keys to be added to the "options" object; see 'notistack' docs
                const newOptions = { 
                    action: () => button,
                    onExited: () => {
                        // remove from redux
                        dispatch(removeSnackbar(uniqueID));
                        removeDisplayed(uniqueID);
                    } 
                }
                const mergedOptions = merge(snack.get("options", Map({})), newOptions);

                enqueueSnackbar(
                    snack.get("message", ""),
                    mergedOptions.toJS()
                );
                // keep track of snackbars that we've displayed
                storeDisplayed(uniqueID);
            });

        } catch (error){}

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [notifications]);

    return null;
}

export default Snackbar;
