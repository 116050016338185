import { fromJS, List } from 'immutable';


export const reportsActionTypes = {
    // action.bool
    'reports/setLoading': 'reports/setLoading',

    // action.reports
    'reports/setReports': 'reports/setReports',

    // action.templateOptions
    'reports/setTemplateOptions': 'reports/setTemplateOptions',
    
    // action.templateObj
    'reports/updateTemplateOptions': 'reports/updateTemplateOptions',
    // action.id
    'reports/delete': 'reports/delete',
};

const SET_SELECT_ROW_ID = "SET_SELECT_ROW_ID";

// Action creators

export const setSelectedRowID = id => ({
    type: SET_SELECT_ROW_ID,
    rowID: id
});


const initialState = fromJS({
    templateOptions: [],
    loading: true,
    reports: [],
    selectedRowID: "grandTotal"
});

const reportsReducer = (state=initialState, action) => {
    switch(action.type){

        case reportsActionTypes['reports/setLoading']:
            const _bool = action.bool;

            return state.set('loading', _bool);

        case reportsActionTypes['reports/setReports']:
            // Shape the api reports response into the shape we want for the
            // table
            let _reports = action.reports || List([]); 
            _reports = _reports.reverse();

            _reports = _reports.map(reportObj => {
            // console.log("reportsReducer -> reportObj", reportObj.toJS())
                const equipment = reportObj.get("equipment", "None");
                let equipmentListString = ""; 

                equipment.forEach((equip, index) => {                    
                    if (equipment.size > 1 && index > 0) {
                        equipmentListString += `, ${equip}`;
                    } else {
                        equipmentListString += equip;
                    }
                });

                return fromJS({
                    date_from: reportObj.get("date_from", ""),
                    date_to: reportObj.get("date_to", ""),
                    export_formats: reportObj.get("export_formats", []),
                    equipment: equipmentListString,
                    id: reportObj.get("id", ""),
                    jobs: reportObj.get("jobs", ""),
                    name: reportObj.get("name", ""),
                    // submitted: reportObj.get("submitted", false),
                    type: reportObj.get("template", "No Type"),
                    user: reportObj.get("user", ""),
                });
            });


            let _reportsState = state.set('reports', _reports);
            _reportsState = _reportsState.set('loading', false);

            return state.merge(_reportsState);

        case reportsActionTypes['reports/setTemplateOptions']:

            const _templateOptions = action.templateOptions;
            const _templateOptionsState = state.set('templateOptions', _templateOptions);

            return state.merge(_templateOptionsState);

        case reportsActionTypes['reports/updateTemplateOptions']:
            const _templateObj = action.templateObj;
            let _newTemplates = state.get('templateOptions', List([]));
            // index value to delete or -1 for new template
            const newTemplate = _newTemplates.indexOf(_templateObj);

            if (newTemplate) _newTemplates = _newTemplates = _newTemplates.push(_templateObj);
            // in this case newTemplate is actually the old tempalte index
            else _newTemplates = _newTemplates = _newTemplates.delete(newTemplate);

            return state.set('templateOptions', _newTemplates);

        case reportsActionTypes['reports/delete']:

            const reportID = action.id;
            let _deleteReportState = state.get('reports', List([]));
            const reportIndex = _deleteReportState.findIndex(_report => {
                return _report.get('id', '') === reportID;
            })

            if (reportIndex !== -1) {
                _deleteReportState = _deleteReportState.delete(reportIndex)
            } 

            return state.set('reports', _deleteReportState);

        case SET_SELECT_ROW_ID:
            const tableRowID = action.rowID;
           return state.set("selectedRowID", tableRowID);

        default:
            return state;
    };
};


export default reportsReducer;
