import React from 'react';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Typography,
  LinearProgress,
} from '@mui/material';

function UploadResponseView({
  retroFiles,
}){
  return (
    <div
      style={{
        margin: '2 auto',
        marginBottom: '2.5em',
        width: '100%',
        overflow: 'auto',
        maxHeight: '70%',
        minHeight: '70%',
      }}
    >
      <div 
        style={{
          paddingLeft: '2em',
          // justifyContent: 'space-evenly',
          alignItems: 'baseline',
          minWidth: '100%',
          maxWidth: '100%',
        }}
      >
        {retroFiles.map((file, index) => {
          const wasSuccessfull = file.wasSuccessfull; 
          const name = file.file.name;
          const errors = file.errors;
          const responseID = file.responseID;
          return (
            <div
              key={responseID}
              style={{
                display: 'flex',
                marginTop: '2em',
                flexDirection: 'column',
                width: '100%',
                paddingLeft: '2em',
                // justifyContent: 'space-evenly',
                // alignItems: 'baseline',
                minWidth: '100%',
                maxWidth: '100%',
              }}
            >
              <div style={{display: 'flex', width: '100%'}}>
                <Typography>{name} </Typography>
                {wasSuccessfull === true && <CheckCircleIcon style={{color: 'green', marginLeft: '.5em'}}/>}
                {wasSuccessfull === false && <SmsFailedIcon style={{color: 'red', marginLeft: '.5em'}}/>}
              </div>
              <div style={{width: '60%'}}>
                {wasSuccessfull === null && <LinearProgress/>}
                {wasSuccessfull === false && (
                  errors.map(error => {
                    return (
                      <div
                        key={error}
                        style={{marginLeft: '1em'}}
                      >
                  * {error}
                </div>
                    )
                  })
                )}
              </div>
            </div>

          );
        })}
      </div>
    </div>
  );
};


export default UploadResponseView;
