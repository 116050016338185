import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from '../shared/styles/appStyles';
import RegisterView from './RegisterView';


RegisterContainer.propTypes = {
    match: PropTypes.object.isRequired,
};


function RegisterContainer(props) {

    const { match } = props;

    const [trialUser, setTrialUser] = useState(false);
    const [token, setToken] = useState("");

    useEffect(() => {
        const trialUserParam = match.params && match.params.trialUser 
            ? match.params.trialUser 
            : false;
        const tokenParam = match.params && match.params.token 
            ? match.params.token 
            : "";

        setTrialUser(trialUserParam);
        setToken(tokenParam);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RegisterView trialUser={trialUser} token={token}/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default RegisterContainer
