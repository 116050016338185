import { merge, Map, fromJS, List } from 'immutable';
import { API, OrgDateTime } from '../../Root';
import * as Sentry from '@sentry/react';
import { catchBlockErrors } from '../utils/sentry';

import { appActionTypes } from './app_actionTypes';


export const updateUnitType = (apiResponse) => ({
    type: appActionTypes['updateUnitType'],
    apiResponse
});

export const setOrganization = (org) => ({
    type: appActionTypes['org/setOrg'],
    org
})

export const setUserData = (user) => ({
    type: appActionTypes['user/setUserData'],
    user,
});

export const changeUserOrg = (user) => ({
    // user is apiResponse
    type: appActionTypes['app/changeUserOrg'],
    user
});

export const updateOrganizationTimezone = (apiResponse) => ({
    type: appActionTypes['app/updateOrganizationTimezone'],
    apiResponse
});

export const updateOrganizationDisplayName = (apiResponse) => ({
    type: appActionTypes['app/updateOrganizationDisplayName'],
    apiResponse
});

export const removeSnackbar = (key) => {
    return {
        type: appActionTypes['app/removeSnackbar'],
        key
    }
};

export const showSnackbar = ({ message, persist = false, variant = "default" }) => {
    return {
        type: appActionTypes['app/showSnackbar'],
        notification : fromJS({
            message,
            options: {
                // key: unique id'ing for closing purposes
                key: Math.random(),
                // false = auto dismiss snackbar
                persist,
                // Dictates coloring of snackbar
                variant
            }
        })
    }
};

// Thunk
export const fetchUserAndOrgInfo = (userId, permissions) => {
    return async (dispatch) => {
        try {
            dispatch({type: 'setPermissions', permissions: fromJS(permissions)});
            const userResponse = await API.subscribe(
                {
                    endpoint: API.endpoints['SL/userInfo'],
                }, setUserData
            )
            if (userResponse && !userResponse.error) {
                const id = userResponse.get('_id', 'Id not found');
                const username = userResponse.get('username', 'username not found');
                const email = userResponse.get('email', 'email not found');
                const _userObj = { id, username, email };

                // setting sentry user scope
                Sentry.configureScope(scope => { scope.setUser(_userObj) });
                Sentry.configureScope(scope => { scope.setTag("User", username) })

                // await API.subscribe({
                //     endpoint: API.endpoints['segmentationTypes'],
                //     method: 'get'
                // }, setSegmentationTypes);

                await API.subscribe({
                    endpoint: API.endpoints['SL/orgInfo'],
                    method: 'get',
                }, setOrganization);


            }
        } catch (error) {

            catchBlockErrors({ error, fileFunction: 'app_s/setAppStateAndUrlAction' });
        };
    }
}



// Reducer
const initState = fromJS({});


const appReducer = (state = initState, action) => {

    switch (action.type) {

        case 'setPermissions':
            return state.set('permissions', action.permissions);
            // Temp add templates page to user pages
        case appActionTypes['user/setUserData']:
            // let userPages = action.user.get('pages').push(Map({_id: 'jobs', label: 'Jobs', link: '/jobs'}));
            // const _user = action.user.set('pages', userPages);
            // let _userState = state.set('user', _user)
            const permissions = state.get('permissions', List([]));
            let userPages = action.user.get('pages');

            if (permissions.includes('read:reports')){
                userPages = userPages.push(Map({_id: 'reporting', label: 'New Reports', link: '/reporting'}));
            }
            const _user = action.user.set('pages', userPages);
            let _userState = state.set('user', _user)

            return merge(state, _userState);

        case appActionTypes['app/updateOrganizationTimezone']:
        case appActionTypes['app/updateOrganizationDisplayName']:
            const orgData = action.apiResponse;
            const _timezone = orgData.get('timezone', null);
            const currentOrgState = state.get("organization", Map({}));

            if (_timezone !== null) {
                OrgDateTime.setTimeZone(_timezone);
            }
            
            const updatedCurrentOrgState = merge(currentOrgState, orgData);
            const updatedOrgState = state.set("organization", updatedCurrentOrgState);

            return merge(state, updatedOrgState);

        case appActionTypes['app/changeUserOrg']:
            const user = action.user;

            let updatedUserState;

            if (!user.error) {
                const newUser = state
                    .get('user', Map({}))
                    .merge(user)

                updatedUserState = state.set('user', newUser);
                const snackbarMessageUpdate = fromJS({
                    message: "Update successful!",
                    options: {
                        key: Math.random(),
                        persist: false,
                        variant: "success"
                    },
                });

                const snackSuccessState = updatedUserState
                    .get("notifications", List([]))
                    .push(snackbarMessageUpdate);

                updatedUserState = updatedUserState.set("notifications", snackSuccessState);


            } else {
                const snackbarMessageFail = fromJS({
                    message: "Failed to update",
                    options: {
                        key:  Math.random(),
                        persist: true,
                        variant: "error"
                    }
                })
                const snackFailState = state
                    .get("notifications", List([]))
                    .push(snackbarMessageFail);

                updatedUserState = state.set("notifications", snackFailState);
            }

            return merge(state, updatedUserState);

        case appActionTypes['org/setOrg']:
            const org = action.org;
            const _orgIDState = state.set('organization', org);


            return merge(state, _orgIDState);

        case appActionTypes['app/showSnackbar']:
            const snackbarArray = state
                .get("notifications", List([]))
                .push(action.notification);

            const snackbarState = state.set("notifications", snackbarArray);

            return merge(state, snackbarState);

        case appActionTypes['app/removeSnackbar']:
            const snackbarRemoveArray = state.get("notifications", List([]));
            // returns all others BUT the one with the passed in key
            const filteredArray = snackbarRemoveArray.filterNot(snack => {
                const snackKey = snack.getIn(["options", "key"], "");
                return snackKey === action.key
            });
            const filteredState = state.set("notifications", filteredArray);

            return merge(state, filteredState);


        case appActionTypes['updateUnitType']:

            const unitKey = action.apiResponse.get('id', '');
            const unitValue = action.apiResponse.get('value', '');

            if (unitKey && unitValue) {
                const unitUpdatedState = state.setIn(['organization', 'unit_settings', unitKey], unitValue);
                return state.merge(unitUpdatedState);
            };

            return state;

        default:
            return state
    };
};


export default appReducer;
