export const skyviewActionTypes = {
    'skyview/setSourceIDAndName': 'skyview/setSourceIDAndName',
    'skyview/setSelectedSummaryName': 'skyview/setSelectedSummaryName',
    'skyview/loadingSummaryNames': 'skyview/loadingSummaryNames',

    'skyview/setSegmentationType': 'skyview/setSegmentationType',
    'skyview/setSegmentationTypes': 'skyview/setSegmentationTypes',
    'skyview/setSegmentationID': 'skyview/setSegmentationID',
    'skyview/setMapView': 'skyview/setMapView',
    'skyview/updateExtraLayers': 'skyview/updateExtraLayers',
    'skyview/setSummaryNames': 'skyview/setSummaryNames',
    'skyview/setSourceNames': 'skyview/setSourceNames',
    'skyview/setSelectedLayer': 'skyview/setSelectedLayer',
    'skyview/loadingSourceNames':'skyview/loadingSourceNames', 
    'skyview/toggleLayersPanel':'skyview/toggleLayersPanel', 
    'skyview/toggleLOP':'skyview/toggleLOP', 
    'skyview/toggleAdvancedFilters':'skyview/toggleAdvancedFilters', 
    'skyview/toggleLayerVisibility': 'skyview/toggleLayerVisibility',
    'skyview/resetLayer': 'skyview/resetLayer',
    'skyview/addLayer': 'skyview/addLayer',
    'skyview/removeLayer': 'skyview/removeLayer',
    'skyview/setMapDataLoading': 'skyview/setMapDataLoading',
    'skyview/setFirstLoad': 'skyview/setFirstLoad',
    'skyview/setLayerFeatureCollection': 'skyview/setLayerFeatureCollection',
    'skyview/handleMapLayerClick': 'skyview/handleMapLayerClick',
    'skyview/setMapLayerIdAndListeners': 'skyview/setMapLayerIdAndListeners',
    'updateDateRange': 'updateDateRange',
    'clearLayerUpdates': 'clearLayerUpdates',
    'toggleLayerVisibility': 'toggleLayerVisibility',
    'dropSelectedSegment': 'dropSelectedSegment',
    'toggleExportModal': 'toggleExportModal',
    'setReportId': 'setReportId',

};

// export default skyviewActionTypes;
