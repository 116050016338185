import Immutable from 'immutable';
const applicationLayerObj = Immutable.fromJS({
    type: 'application',
    label: 'Application',
    idColor: 'blue',
    advancedFilters: {
        segmentation: 'pattern',
    },
});


export default applicationLayerObj;
