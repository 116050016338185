import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { v4 as uuidv4 } from 'uuid';
import { API } from '../../Root';
import UploadFilesView from './UploadFilesView';
import UploadResponseView from './UploadResponseView';
import { catchBlockErrors } from '../../shared/utils/sentry';
import { 
  Typography,
  Button,
} from '@mui/material';


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '70%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    // display: 'flex',
    // flexDirection: 'column',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


function RetroModalUploader({closeModal, equipment}){

  const classes = useStyles();

  const [retroFiles, setRetroFiles] = useState([
    // {
    //   file: {name: 'p9 PRI 234 CONTROL SOUTH WS _ 528ft.csv'},
    //   wasSuccessfull: false,
    //   errors: ["this is one test error message", "Something was up with the lat on line 85"]
    // },
    // {
    //   file: {name: 'p9 PRI 234 CONTROL SOUTH WS _ 528ft.csv'},
    //   wasSuccessfull: false,
    //   errors: ["this is one test error message", "Something was up with the lat on line 85"]
    // },
    // {
    //   file: {name: 'aowjfeoiawoifjaowifoiawfojwaeoifjwaojfp9 PRI 234 CONTROL SOUTH WS _ 528ft.csv'},
    //   wasSuccessfull: false,
    //   errors: ["this is one test error message", "Something was up with the lat on line 85"]
    // },

    // {
    //   file: {name: 'p9 PRI 234 CONTROL SOUTH WS _ 528ft.csv'},
    //   wasSuccessfull: false,
    //   errors: ["this is one test error message", "Something was up with the lat on line 85"]
    // },
  ]);
  const [isUploading, setIsUploading] = useState(false);


  const handleFileSelect = (event) => {
    let files = event.target.files;
    files = Array.from(files).map((file, index) => {
      const id = uuidv4();
      return {
        file: file,
        // equipmentType: '',
        id: id,
        wasSuccessfull: null,
        errors: [],
        serialNumber: '',
      };
    });

    setRetroFiles(retroFiles => retroFiles.concat(files));
    event.target.value = null;

  };

  const handleEquipmentSelect = (serialNumber, index) => {
    const file = retroFiles[index];
    file.serialNumber = serialNumber;
    setRetroFiles(prevState => {
      prevState[index] = file;
      return [...prevState];
    });
  };

  // const handleEquipmentTypeSelect = (equipmentType, index) => {
  //   const file = retroFiles[index];
  //   file.equipmentType = equipmentType;
  //   setRetroFiles(prevState => {
  //     prevState[index] = file;
  //     return [...prevState];
  //   });
  // };

  const removeFileFromList = (id) => {
    setRetroFiles(retroFiles.filter(file => file.id !== id));
  };

  // const filesMissingEquipmentType = retroFiles.filter(file => file.equipmentType === '').length;
  const filesMissingEquipment = retroFiles.filter(file => file.serialNumber === '').length;
  const canUpload = (
    retroFiles.length > 0 
    // && filesMissingEquipmentType === 0 
    && filesMissingEquipment === 0
  );

  const clearFiles = () => {
    setRetroFiles([]);
    setIsUploading(false);
  };


  const uploadAllFiles = () => {
    try {
      setIsUploading(true);
      retroFiles.forEach((file, index) => {
        uploadFile(file, index);
      });
    } catch(error) {
      catchBlockErrors({error, fileFunction: 'RetroModalUploader/uploadAllFiles'});
    }
  };

  const uploadFile = async(_fileObj, index) => {
    try {
      const { file, serialNumber } = _fileObj;
      const response = await API.fileUpload({
        endpoint: API.endpoints['retroFileUpload'],
        options: {
          file: file,
        },
        data: {
          // equipment: equipmentType,
          serial_number: serialNumber,
        },
      });

      if (
        response.error 
        && response.error.response
        && response.error.response.data
        && response.error.response.data.errors
      ) {
        const errorFeedback = response.error.response.data.errors;

        setRetroFiles(prevState => {
          prevState[index]["errors"] = errorFeedback;
          prevState[index]["wasSuccessfull"] = false;
          return [...prevState];
        });
      } else if (!response.error) {
        setRetroFiles(prevState => {
          prevState[index]["wasSuccessfull"] = true;
          return [...prevState];
        });

      };
    } catch(error) {
      catchBlockErrors({error, fileFunction: 'RetroModalUploader/uploadFile'});
    }
  };

  return (
    <div 
      className={classes.paper}
    >
      <div 
        style={{
          display: 'flex',
          width: '100%',
          borderBottom: '2px solid grey',
          marginBottom: '.5em',
        }}
      >
        <div
          style={{ 
            display: 'flex',
            margin: '0 auto',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h5"
          >
            Retroreflectivity Files To Upload
          </Typography>
          <Typography
          >
            Accepted File Types Are CSV and TXT
          </Typography>
        </div>
        <Button 
          style={{alignSelf: 'flex-start', minWidth: 0}}
          onClick={closeModal}
          variant="outlined">
          X
        </Button>
      </div>
      { isUploading ? (
        <UploadResponseView
          retroFiles={retroFiles}
        />
      ) : (
        <UploadFilesView
          equipment={equipment}
          // handleEquipmentTypeSelect={handleEquipmentTypeSelect}
          handleEquipmentSelect={handleEquipmentSelect}
          removeFileFromList={removeFileFromList}
          retroFiles={retroFiles}
          handleFileSelect={handleFileSelect}
        />
      )}
      <Button 
        variant="contained" 
        disabled={!canUpload}
        onClick={isUploading ? clearFiles : uploadAllFiles}
      >
        {isUploading ? 'Clear Files' : 'Upload'}
      </Button>
    </div>
  );
};


export default RetroModalUploader;
