import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import appStyle from '../styles/appStyles';
// Components
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(appStyle); // Hook for injecting styles

// wherever this component is placed, the parent div must have explicit positioning;
// i.e. position: "relative", "absolute", or "sticky"

function SpinnerLoading(props) {

    const classes = useStyles();

    return (
        <div className={classes.centerInParentDiv}>
            <CircularProgress />
        </div>
    )
}

export default SpinnerLoading;