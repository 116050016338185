import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import { Route } from 'react-router-dom';
import { useAuth0 } from "./shared/auth/react-auth0-spa";

import history from "./shared/history";
import rootReducer from './shared/stores/root_s';
import _API from './shared/API';
import  OrganizationDateTime from './shared/dates';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './shared/styles/appStyles';
import LoginPage from './shared/auth/LoginPage';
import { catchBlockErrors } from './shared/utils/sentry';
import App from './App';
import Loading from './shared/components/SpinnerLoading';
import { SnackbarProvider } from 'notistack';



const store = createStore(
    rootReducer,
    applyMiddleware(ReduxThunk)
);

// export the api class after we pass it dispatch
export const API = new _API(store.dispatch);
export const OrgDateTime = new OrganizationDateTime();


function Root(props) {

    const { loading, isAuthenticated } = useAuth0();

    // Only way to add styling to the body tag
    // overscroll-behavior prevents scroll bounce at ends of content

    try {
        // if auth0 is loading
        if (loading) {
            return (<div><Loading /></div>);
        } else if (!isAuthenticated) {
            return <LoginPage />
        }
    } catch(error){
        catchBlockErrors({error, fileFunction: 'Root/second one'});
        return <LoginPage />
    };

    // only return the app once we are authenticated
    return isAuthenticated && (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider 
                        maxSnack={10} 
                        style={{anchorOriginBottomLeft: {top: -70}}}>
                        <Route
                            history={history}
                            component={App}
                            path="/:tool?/:organizationID?"
                            {...props}
                        />
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}


export default Root;
