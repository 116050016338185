export const appActionTypes = {
    'user/setUserData': 'user/setUserData',
    'org/setOrg': 'org/setOrg',
    'app/showSnackbar': 'app/showSnackbar',
    'app/removeSnackbar': 'app/removeSnackbar',
    'app/changeUserOrg': 'app/changeUserOrg',
    'app/updateOrganizationTimezone': 'app/updateOrganizationTimezone',
    'app/updateOrganizationDisplayName': 'app/updateOrganizationDisplayName',
    'updateUnitType': 'updateUnitType',
};
