import React from 'react';
import { useAuth0 } from "./react-auth0-spa";
import { Route, Redirect } from "react-router-dom";
import MainContainerErrorBoundary from '../components/MainContainerErrorBoundary';

function PrivateRoute({
    component: Component,
    firstName,
    lastName,
    tool,
    message,
    ...rest
}) {

    const { isAuthenticated } = useAuth0();

    return (
        <Route
            {...rest}
            render={(routerProps) => {
                return (
                    isAuthenticated 
                    ? (
                        <MainContainerErrorBoundary
                            firstName={firstName}
                            lastName={lastName}
                            tool={tool}
                            message={message}
                            category={'BUG PAGE'}
                        >
                            <Component {...routerProps} {...rest}/>
                        </MainContainerErrorBoundary>

                    )
                    : (
                        <Redirect 
                            to={{
                                pathname: "/",
                                state: { from: routerProps.location }
                            }}            
                            />
                    )
                )
            }}
        />

    );
}


export default PrivateRoute;
