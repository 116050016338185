import React, { Suspense, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Map } from 'immutable';
import CssBaseline from '@mui/material/CssBaseline';
import history from "./shared/history";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from "./shared/auth/react-auth0-spa";
import routes from './shared/configs/routes_c';
import * as Sentry from '@sentry/react';
import { Router, Switch } from "react-router-dom";
// components
import NavBarContainer from './nav/NavContainer';
import Loading from './shared/components/SpinnerLoading';
import PrivateRoute from './shared/auth/PrivateRoute';
import Snackbar from './shared/components/Snackbar';
import { catchBlockErrors } from './shared/utils/sentry';
import jwt_decode from "jwt-decode";

// functions
import { API } from './Root';

import { fetchUserAndOrgInfo } from './shared/stores/app_s';

// Util vars
const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const AppWrapper = styled("main")(({ theme }) => ({
    bottom: 0,
    // display: "-webkit-box",
    display: "block",
    // "-webkit-box-orient": "vertical",
    // "-webkit-box-direction": "normal",
    left: 0,
    // overflow: "hidden",
    overflowY: "auto",
    position: "fixed", // fixed to size of viewport
    right: 0,
    top: 0,
    zIndex: 0,
    background: theme.palette.primary.offWhite
}));

const MainContentContainer = styled("section")({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    // overflowY: "hidden", 
    height: `calc(100% - 48px)`, // 64px is height of top navbar
    position: "relative",
});


function App(props) {

    const dispatch = useDispatch();

    const { isAuthenticated, user, getTokenSilently } = useAuth0();

    const [appLoading, setAppLoading] = useState(true);
    // For Intercom widget
    const firstName = useSelector(state => state.app.getIn(
        ['user', 'name', 'firstName'], ''
    ))
    const lastName = useSelector(state => state.app.getIn(
        ['user', 'name', 'lastName'], ''
    ))
    const intercomHash = useSelector(state => state.app.getIn(
        ['user', 'intercom_hash'], '')
    );
    const userID = useSelector(state => state.app.getIn(['user', '_id'], ''));
    const username = useSelector(state => state.app.getIn(['user', 'username'], ''));
    const email = useSelector(state => state.app.getIn(['user', 'email'], ''));
    const orgName = useSelector(state => state.app.getIn(['organization', 'name'], 'none'));
    const _user = useSelector(state => state.app.getIn(['user'], Map({})));

    // fetch and set user data and set token
    useEffect(() => {
        setAppLoading(true);
        if (user && isAuthenticated) {
            try {
            (async () => {
                // user is the user we get back from auth0
                const userId = user.sub.split('|')[1];
                const token = await getTokenSilently();

                // set the token on the API class here so we don't even need to
                // use it again
                API.setToken(token);
                const permissions = jwt_decode(token).permissions;
                console.log('permissions', permissions);


                // this is also setting the user info so we should rename it to
                // better reflect what it is doing
                await dispatch(fetchUserAndOrgInfo(userId, permissions));
                setAppLoading(false);
            })();
            } catch(error) {
                catchBlockErrors({error, 'file/function': 'App.js fetch set users data'});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user]);

    useEffect(() => {
        // Display Intercom widget
        if (intercomHash) {
            try {
                window.Intercom("boot", ({
                    app_id: APP_ID,
                    user_id: userID, // User ID from get_user call
                    user_hash: intercomHash, // HMAC using SHA-256 from backend
                    name: `${firstName} ${lastName}`
                }));
            } catch(error) {
                catchBlockErrors({error, 'file/function': 'App.js intercom boot'});
            }
        }
        // clears Intercom cookie
        return () =>  window.Intercom('shutdown');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[intercomHash]);

    useEffect(() => {

        if (_user.size > 0 && !appLoading) {
            const url = (
                history
                && history.location
                && history.location.pathname
                && history.location.pathname !== '/'
            ) || `/dashboard/${orgName}`;

            history.push(url);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_user, appLoading]);

    useEffect(() => {
        // Display Intercom widget
        if (username) {
            const _userObj = { id: userID, username, email };
            Sentry.configureScope(scope => { scope.setUser(_userObj) });
            Sentry.configureScope(scope => { scope.setTag("User", username) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[username]);


    if (appLoading) {
        return <div><Loading /></div>;
    }

    return (
        <Router history={history}>
            <CssBaseline />
            <AppWrapper
                // sx={{
                //     overflow: 'auto',
                //     background: '#FAFAFA'
                // }}
            >
                <Snackbar />
                <PrivateRoute 
                    path='/' 
                    component={NavBarContainer} 
                    firstName={firstName}
                    lastName={lastName}
                    tool={'/ URL ROUTE'}
                    message={'Bug page error on "/" route'}
                    {...props}
                />
                <Suspense fallback={<div><Loading/></div>}>
                    <MainContentContainer>
                        <Switch>
                            {routes.map((route, index) => {
                                return (
                                    <PrivateRoute
                                        firstName={firstName}
                                        lastName={lastName}
                                        tool={route.tool}
                                        message={route.errorMessage}
                                        key={index}
                                        exact path={route.path}
                                        component={route.component}
                                    />
                                )
                            })}
                        </Switch>
                    </MainContentContainer>
                </Suspense>
            </AppWrapper>
        </Router>
    );
}

export default App;
