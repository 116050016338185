import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import specRiteLogo from '../images/logo-for-auth0.png';
import history from "../shared/history";
import { Drawer, List as Ul, ListItem } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


SideBarContainer.propTypes = {
    //func
    toggleSideBar: PropTypes.func.isRequired,
    //vars
    sideBarOpen: PropTypes.bool.isRequired
}


const drawerWidth = 140;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        background: "#f0efef", // overriding background of white
        width: 200,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '.5em',
        marginBottom: '.5em',
    },
    // appBar: {
    //     [theme.breakpoints.up('sm')]: {
    //         width: `calc(100% - ${drawerWidth}px)`,
    //         marginLeft: drawerWidth,
    //     },
    // },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    // toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


function SideBarContainer(props) {

    const classes = useStyles();

    const { toggleSideBar, sideBarOpen, settingsPage, setSettingsPage } = props;


    const clearPanelIDAndCloseSideBar = (page) => {
        closeSidebar();
        setSelectedPage(page);
        if (settingsPage) setSettingsPage(false);
    }

    const pages = useSelector(state => state.app.getIn(['user', 'pages'], List([])));
    const organizationID = useSelector(state => state.app.getIn(['organization', 'name'], 'noOrgID'));

    const [selectedPage, setSelectedPage] = useState('none');


    const closeSidebar = () => {
        if (sideBarOpen) toggleSideBar(false);
    };


    useEffect(() => {
        let selectedPage = history
            && history.location
            && history.location.pathname
            && history.location.pathname.length
            ? history.location.pathname.split('/')[1]
            : "none"

        if (!selectedPage) selectedPage = 'dashboard';

        setSelectedPage(`/${selectedPage}`);
    }, []);

    useEffect(() => {
        if (settingsPage) {
            setSelectedPage(`none`);
        }

    }, [settingsPage]);

    return (
        <Drawer
            variant="temporary"
            anchor='left'
            open={sideBarOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <div onMouseLeave={closeSidebar} style={{ height: '100%' }}>
                <div className={classes.drawerHeader}>
                    <img src={specRiteLogo} height={40} width={40} alt="Spec-Rite Logo" />
                </div>
                <Divider />
                {pages.size ? (
                    <Ul style={{ marginTop: '1em' }}>
                        {pages.map((page, index) => {
                            const thisPage = page.get('link', '');
                            const link = `${thisPage}/${organizationID}`
                            const handleLinkSelect = () => {
                                clearPanelIDAndCloseSideBar(thisPage);
                            };

                            return (
                                <Link
                                        key={thisPage}
                                    to={link}
                                    onClick={handleLinkSelect}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit"
                                    }}
                                >
                                    <ListItem
                                        button
                                        // key={thisPage}
                                        style={thisPage === selectedPage
                                                ? {
                                                    background: "#CF2031",
                                                    color: "white"
                                                }
                                                : {
                                                    paddingBottom: "4px",
                                                    color: "black"
                                                }
                                        }
                                    >
                                        <ListItemText primary={page.get('label', 'None')} />
                                    </ListItem>
                                </Link>
                            );
                        })}
                    </Ul>
                ) : null}
            </div>
        </Drawer>
    );
}


export default SideBarContainer;
