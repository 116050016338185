import React, { useState, useEffect } from 'react';
import RetroModalUploader from './RetroModalUploader';
import { List } from 'immutable';
import { API } from '../../Root';
// Components
import { Modal } from '@mui/material';


function UploadContainer({isModalOpen, closeModal}) {

    const [equipment, setEquipment] = useState(List([]));

    useEffect(() => {
        const fetchRetroEquipment = async () => {
            const response = await API.call({
                endpoint: API.endpoints['retro/equipment'],
                method: 'get',
            });
            if (!response.error) {
                setEquipment(response);
            };
        };

        fetchRetroEquipment();

    }, []);

    return (
        <div style={{ paddingLeft: "2%"}}>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <div>
                    <RetroModalUploader 
                        equipment={equipment}
                        closeModal={closeModal}
                    />
                </div>
            </Modal>
        </div>
    )
}


export default UploadContainer;
