import Immutable from 'immutable';

const sourceResetLayerObj = Immutable.fromJS({
    nameOfSelectedSummary: [{label: 'All', value: 'all'}],
    summaryNames: [{label: 'All', value: 'all'}],
    selectedSegment: '',
    segmentFeatureCollection: {}
});

export default sourceResetLayerObj;
