import { fromJS, List } from 'immutable';


export const newReportsActionTypes = {
    'setName': 'setName',
    'dateFromUpdate': 'dateFromUpdate',
    'dateToUpdate': 'dateToUpdate',
    'selectedEquipmentUpdate': 'selectedEquipmentUpdate',
    'selectedJobsUpdate': 'selectedJobsUpdate',
    'setTemplateID': 'setTemplateID',
    'setTemplateObj': 'setTemplateObj',
    'bulkJobSelect': 'bulkJobSelect',
    'bulkEquipmentSelect': 'bulkEquipmentSelect',
    'setEquipmentLoaded': 'setEquipmentLoaded',

    'setDateRange': 'setDateRange',
    'initState': 'initState',
    'resetState': 'resetState',
    'loading': 'loading',
};

export const setLoadingState = (bool) => ({
    type: newReportsActionTypes['loading'],
    bool
});

export const resetNewReportStore = () => ({
    type: newReportsActionTypes['resetState'],
});

export const initState = (state) => ({
    type: newReportsActionTypes['initState'],
    state
})

export const setDateRange = (dateRange) => ({
    type: newReportsActionTypes['setDateRange'],
    dateRange
});

export const setName = (name) => ({
    type: newReportsActionTypes['setName'],
    name
});

export const setDateFrom = (date_from) => ({
    type: newReportsActionTypes['dateFromUpdate'],
    date_from
});

export const setDateTo = (date_to) => ({
    type: newReportsActionTypes['dateToUpdate'],
    date_to
});

export const updateSelectedEquipment = (serialNumber) => ({
    type: newReportsActionTypes['selectedEquipmentUpdate'],
    serialNumber
});

export const updateSelectedJobs = (jobName) => ({
    type: newReportsActionTypes['selectedJobsUpdate'],
    jobName
});

export const setTemplateID = (templateID) => ({
    type: newReportsActionTypes['setTemplateID'],
    templateID
});

export const setTemplateObj = (templateObj) => ({
    type: newReportsActionTypes['setTemplateObj'],
    templateObj
});

export const bulkJobSelect = (jobs) => ({
    type: newReportsActionTypes['bulkJobSelect'],
    jobs
});

export const bulkEquipmentSelect = (equipment) => ({
    type: newReportsActionTypes['bulkEquipmentSelect'],
    equipment
});

export const setEquipmentLoaded = (equipment=null) => ({
    type: newReportsActionTypes['setEquipmentLoaded'],
    equipment
});

const initialState = fromJS({
    reportName: '',
    selectedJobs: [],
    selectedEquipment: [],
    templateID: '',
    temlateObj: {},
    loading: false,
    equipmentLoading: true,

    dateRange: [],
});

const newReportsReducer = (state=initialState, action) => {
    switch(action.type){

        case newReportsActionTypes['setEquipmentLoaded']:
            const _initalEquipmentSelected = action.equipment;
            if (_initalEquipmentSelected === null) {
                return state.set('equipmentLoading', false);
            } else {
                let _initSelectedEquipment = state.get('selectedEquipment', List([]))
                _initSelectedEquipment = _initSelectedEquipment.push(_initalEquipmentSelected)
                return state
                    .set('equipmentLoading', false)
                    .set('selectedEquipment', _initSelectedEquipment)
            }

        case newReportsActionTypes['loading']:
            const _loadingState = action.bool;
            return state.set('loading', _loadingState);

        case newReportsActionTypes['resetState']:
            return initialState;

        case newReportsActionTypes['initState']:
            const _initState = action.state;
            return state.merge(_initState);

        case newReportsActionTypes['setName']:
            const _name = action.name;
            return state.set('reportName', _name);

        case newReportsActionTypes['setDateRange']:
            const _dateRange = action.dateRange;
            return state.set('dateRange', _dateRange);

        // case newReportsActionTypes['dateFromUpdate']:
        //     const _date_from = action.date_from;
        //     return state.set('date_from', _date_from);

        // case newReportsActionTypes['dateToUpdate']:
        //     const _date_to = action.date_to;
        //     return state.set('date_to', _date_to);

        case newReportsActionTypes['setTemplateID']:
            const _templateID = action.templateID;
            return state.set('templateID', _templateID);

        case newReportsActionTypes['setTemplateObj']:
            const _templateObj = action.templateObj;
            return state.set('templateObj', _templateObj);

        case newReportsActionTypes['selectedEquipmentUpdate']:
            const _equSerialNumber = action.serialNumber;
            let _selectedEquipment = state.get('selectedEquipment', List([]));

            const _equIndex = _selectedEquipment.indexOf(_equSerialNumber);

            if (_equIndex === -1) {
                _selectedEquipment = _selectedEquipment.push(_equSerialNumber);
            } else {
                _selectedEquipment = _selectedEquipment.delete(_equIndex);
            };


            return state.set('selectedEquipment', _selectedEquipment);

        case newReportsActionTypes['selectedJobsUpdate']:
            const _jobName = action.jobName;
            let _selectedJobs = state.get('selectedJobs', List([]));

            const _jobIndex = _selectedJobs.indexOf(_jobName);

            if (_jobIndex === -1) {
                _selectedJobs = _selectedJobs.push(_jobName);
            } else {
                _selectedJobs = _selectedJobs.delete(_jobIndex);
            };

            return state.set('selectedJobs', _selectedJobs);

        case newReportsActionTypes['bulkJobSelect']:
            const _newSelectedJobs = action.jobs;
            return state.set('selectedJobs', _newSelectedJobs);

        case newReportsActionTypes['bulkEquipmentSelect']:
            const _newSelectedEquipment = action.equipment;
            return state.set('selectedEquipment', _newSelectedEquipment);

        default:
            return state;
    };
};


export default newReportsReducer;
