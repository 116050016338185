import Immutable from 'immutable';

import dateRangeResetLayerObj from '../layer_objects/dateRangeResetLayerObj';
import sourceResetLayerObj from '../layer_objects/sourceResetLayerObj';
import summaryResetLayerObj from '../layer_objects/summaryResetLayerObj';


const layerResetObjs = {
    date: dateRangeResetLayerObj,
    source: sourceResetLayerObj,
    summary: summaryResetLayerObj,
    // segement: 
    
}

const filterChangeResetState = (
    layerRestObjType,
    updatedLayerObj,
    layerIndex,
    layers,
) => {

    const resetObj = layerResetObjs[layerRestObjType];
    const layerToReset = layers.get(layerIndex);
    const resetLayer = layerToReset.merge(resetObj);
    const updatedLayers = layers.set(layerIndex, resetLayer);

    return Immutable.fromJS({
        mapDataLoading: true,
        layerUpdates: updatedLayerObj,
        layers: updatedLayers,
    });
};

export default filterChangeResetState;
